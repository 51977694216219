import React, {useState} from 'react';
import {Badge, Divider, Drawer, IconButton, Stack, Typography} from "@mui/material";
import Mark from "../../shared/svg/ReactIcon/Mark";
import Notification from "../../shared/svg/ReactIcon/Notification";
import NotificationItem from "./NotificationItem";
import SimpleBarReact from "simplebar-react";
import Scrollbar from "../../Component/scrollbar";



const NotificationSidebar = () => {
    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };
    return (
        <>
            <IconButton>
                <Badge badgeContent={4} color={'warning'} onClick={handleOpenFilter}>
                    <Notification/>
                </Badge>
            </IconButton>
            <Drawer
                anchor="right"
                open={openFilter}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: 400, border: 'none', overflow: 'hidden', backgroundColor: '#F8F8F8' },
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Уведомления
                    </Typography>
                    <IconButton onClick={handleCloseFilter}>
                        <Mark/>
                    </IconButton>
                </Stack>

                <Divider color={"#CCC"} sx={{height: 2}}/>


                <Scrollbar>
                    <Stack spacing={3} p={2}>
                        <NotificationItem/>
                        <NotificationItem/>
                        <NotificationItem/>
                        <NotificationItem/>
                    </Stack>
                </Scrollbar>


            </Drawer>

        </>
    );
};

export default NotificationSidebar;