import React, {useContext} from 'react';
import {Button, IconButton, Paper, Skeleton, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import TariffRowsList from "./TariffRowsList";
import {Link} from "react-router-dom";
import FeedbackModal from "../../entities/FeedbackModal";
import Box from "@mui/material/Box";
import DeleteContent from "../../features/deleteContent/UI/DeleteContent";
import {Context} from "../../index";
import {BsPencilFill} from "react-icons/bs";

const TariffCard = ({
                        isLoading,
                        id,
                        title,
                        period_per_pay,
                        price,
                        city_id,
                        subtitle,
                        short_description,
                        tariff_type
                    }) => {
        const {store} = useContext(Context);
        return (
            <Paper
                component={Stack}
                variant={"elevation"}
                sx={{p: "20px", position: 'relative', height: "100%"}}
                justifyContent={"space-between"}
                >
                {
                    store.isAdmin &&
                    <Box sx={{position: 'absolute', zIndex: '999', right: '8px', top: '8px'}}>
                        <DeleteContent content={'tariff'} id={id}/>
                        <Link to={`/admin/tariff/${id}`}>
                            <IconButton>
                                <BsPencilFill style={{width: '20px', height:'20px'}}/>
                            </IconButton>
                        </Link>
                    </Box>
                }
                <Stack mb={"20px"}>
                    <Typography variant={"h3"}>
                        {isLoading ? <Skeleton width={80}/> : title}
                    </Typography>
                    <Typography variant={"secondaryMedium"} color={"#FF8A00"}>
                        {isLoading ? <Skeleton width={120}/> : subtitle}
                    </Typography>
                </Stack>
                <Stack gap={2}  justifyContent={'flex-end'} minHeight={'320px'}>
                    <Stack sx={{width: "100%"}} justifyContent={"flex-end"} alignItems={"center"}>
                        <TariffRowsList isLoading={isLoading} tariff_type={tariff_type}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} justifyContent={"flex-end"} gap={1}>
                        {
                            isLoading ?
                                <Skeleton width={120}/> :
                                <>
                                    <Typography variant={"h3"}>{price}</Typography>
                                    <Typography variant={"secondaryBold"}>руб <span
                                        style={{color: "#FF8A00"}}>{period_per_pay}</span></Typography>
                                </>
                        }
                    </Stack>
                    <Stack gap={1}>
                        {
                            isLoading ? <Skeleton width={'100%'}/> :
                                <FeedbackModal tariffName={title} name={'Подключить'}/>
                        }
                        {
                            isLoading ? <Skeleton width={'100%'}/> :
                                <Link to={`/tariff/${id}`} style={{textDecoration: 'none'}}>
                                    <Button component={'div'} variant="text" color={'inherit'} fullWidth>
                                        Узнать о тарифе
                                    </Button>
                                </Link>
                        }

                    </Stack>
                </Stack>
            </Paper>
        )
            ;
    }
;

TariffCard.propTypes = {
    id: PropTypes.number,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    city_id: PropTypes.number,
    period_per_pay: PropTypes.string,
    price: PropTypes.number,
    short_description: PropTypes.string,
};

export default TariffCard;