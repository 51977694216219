import {makeAutoObservable} from "mobx"
import axios from "axios";
import Cookies from "js-cookie";

export default class Store{
    constructor() {
        makeAutoObservable(this)
    }
    isAuth = false;
    isAdmin = false;
    cityOption = null

    setAuth(bool) {
        this.isAuth = bool;
    }

    setAdmin(bool) {
        this.isAdmin = bool;
    }

    setCity(option){
        this.cityOption = option;
    }
}