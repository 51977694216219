import React, {useContext} from 'react';
import {Button, Stack, TextField, Typography} from "@mui/material";
import ShortLogo from "../shared/svg/ReactIcon/ShortLogo";
import {Context} from "../index";

const AuthForm = ({onCloseFilter}) => {
    const {store} = useContext(Context)
    const fetchAuth = (e) => {
        store.setAuth(true)
        onCloseFilter()
    }
    return (
        <Stack gap={3} p={2} alignItems={'center'} >
            <ShortLogo/>
            <Typography variant={'primaryBold'} color={"#666"}>Войдите, чтобы продолжить</Typography>
            <Stack spacing={2} width={'100%'}>
                <TextField
                    label={'Логин'}
                    fullWidth
                />
                <TextField
                    label={'пароль'}
                    fullWidth
                />
            </Stack>
            <Button
                variant={"contained"}
                fullWidth olor={"primary"}
                onClick={fetchAuth}
                disabled
            >
                Войти
            </Button>
            <Button variant={"text"} disabled fullWidth color={"inherit"}>
                Не помню пароль
            </Button>
        </Stack>
    );
};

export default AuthForm;