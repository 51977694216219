import React, {useEffect, useState} from 'react';
import {useParams, Link as RouterLink} from "react-router-dom";
import './SIngleNews.css'
import allEndpoints from "../shared/http";
import {Box, Breadcrumbs, Container, Divider, Grid, Link, Skeleton, Stack, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import palette from "../shared/theme/palette";
import useMediaQuery from "@mui/material/useMediaQuery";
import DocumentList from "../widgets/DocumentList";
import {formatDate} from "../shared/helper/date";
import {Helmet} from "react-helmet-async";

const SingleNews = () => {
    const id = useParams().id;
    const isMobile = useMediaQuery('(max-width:600px)');

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [post, setPost] = useState([]);
    const handleGetPost = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.news.getPost(id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                setPost(response.data.result)
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
            setIsError(true)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleGetPost()
    }, [])
    const stringDate = formatDate(post.date)
    const imagePath = post.images && post.images[0]
    const mainImage = `https://www.969975-cv27771.tmweb.ru:${imagePath?.path}`

    return (
        <>
            <Helmet>
                <title>{`Последние новости от Спектр-ТВ: ${post.title}`}</title>
                <meta property="og:title" content={post.title} />
                <meta property="og:type" content="article" />

                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": post.title,
                        "author": {
                            "@type": "Person",
                            "name": "Grigory Panin"
                        },
                        "datePublished": post.date,
                        "dateModified": post.date,
                        "mainEntityOfPage": window.location.href,
                        "publisher": {
                            "@type": "Organization",
                            "name": "ООО СКТВ «Спектр»",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://spektr-tv.su/static/media/spectrum%20logo.5f5cd93db992cbd0a72bb9d2159f7c7e.svg"
                            }
                        }
                    })
                }} />
            </Helmet>
            <Container sx={{flex: 1}}>
                <Stack pt={4} pb={5}>
                    <Breadcrumbs separator={<span style={{color: "#66B3EC"}}>/</span>} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Stack
                        pt={3}
                        direction={{xs: 'column', md: 'row'}}
                        spacing={{xs: 3, md: 0}}
                        justifyContent={'space-between'}
                        alignItems={{xs: 'flex-start', md: 'flex-end'}}
                    >
                        <Typography
                            variant={'h1'}
                            className={'news__container'}
                            lineHeight={{xs: '28px', md: '48px'}}
                        >
                            {post.title}
                        </Typography>
                        <Typography
                            variant={'body2'}
                        >
                            {stringDate}
                        </Typography>
                    </Stack>
                </Stack>

                <Divider
                    sx={{width: "100%", borderTop: '1px solid', borderColor: palette.grey['200']}}
                />
                {
                    imagePath &&
                    <Grid
                        container
                        spacing={2}
                        pt={4}
                    >
                        <Grid item xs={12} md={7}>
                            <Box
                                component={"img"}
                                alt={'news image'}
                                src={mainImage}
                                maxHeight={'590px'}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography variant={'secondaryMedium'} color={palette.grey["400"]}>
                                {post.image_description}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <div className={'body__post__html'} dangerouslySetInnerHTML={{__html: post.body}}/>

                <Stack py={6}>
                    {
                        post.documents &&
                        <DocumentList
                            documents={post.documents}
                        />
                    }
                </Stack>

            </Container>
        </>
    );
};


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/" component={RouterLink}>
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="inherit"
        to="/news"
        component={RouterLink}
    >
        Новости
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        to="/news"
        component={RouterLink}
    >

    </Link>
];
export default SingleNews;