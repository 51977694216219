import React, {useEffect} from 'react';
import {Container, Divider, Link, Stack, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import DocumentRow from "../entities/DocumentRow";
import palette from "../shared/theme/palette";

const Uric = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    })
    const domain = 'https://www.969975-cv27771.tmweb.ru:'
    return (
        <Container>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Юридическая информация'}
            />
            <Stack spacing={{ xs: 2, md: 4 }} pb={5}>
                    {/*<div className={'uric section'}>*/}
                    {/*    <h2>Юридический адрес</h2>*/}
                    {/*    <p>Юридический адрес: 632382, РФ, Новосибирская область, г. Куйбышев, ул. Закраевского,*/}
                    {/*        д.105</p>*/}
                    {/*</div>*/}
                    {/*<div className={'uric section'}>*/}
                    {/*    <h2>Адрес для корреспонденции</h2>*/}
                    {/*    <p>Почтовый адрес: 632387, РФ, Новосибирская область, г. Куйбышев, ул. К.Либкнехта, 1</p>*/}
                    {/*</div>*/}
                    {/*<div className={'uric section'}>*/}
                    {/*    <h2>Лицензии</h2>*/}
                    {/*    <a href="https://spektr-tv.su/docs/lic 2.rtf"*/}
                    {/*       className="text-center"><p>*/}
                    {/*        [СКАЧАТЬ] Лицензия на оказания услуг связи для целей кабельного вещания*/}
                    {/*    </p></a>*/}
                    {/*    <a href="https://spektr-tv.su/docs/telematic.pdf"*/}
                    {/*       className="text-center"><p>[СКАЧАТЬ] Лицензия на телематические услуги связи*/}
                    {/*    </p></a>*/}
                    {/*</div>*/}
                    {/*<a href="https://spektr-tv.su/docs/doc.docx"*/}
                    {/*   className="text-center">[СКАЧАТЬ]</a>*/}
                <Stack spacing={1}>
                    <Typography variant={'h5'}>Реквизиты ООО СКТВ «Спектр»</Typography>
                    <Typography variant={'body1'}>
                        {/*Адреса и реквизиты ООО СКТВ «Спектр»<br/>*/}
                        Почтовый адрес: 632387, Новосибирская область, г. Куйбышев, ул. К.Либкнехта, 1<br/>
                        ИНН 5452112252/КПП 545201001<br/>
                    </Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant={'h5'}>Банковские реквизиты:</Typography>
                    <Typography variant={'body1'}>
                        Р./Сч.: 40702810325040000320<br/>
                        В банке НОВОСИБИРСКИЙ РФ АО "РОССЕЛЬХОЗБАНК"<br/>
                        К./сч.: 30101810700000000784<br/>
                        БИК 045004784 <br/>
                        Тел. (38362) 51-451;8-913-391-08-08<br/>
                        E-mail: <a href="mailto:spektr-sktv@mail.ru">spektr-sktv@mail.ru</a><br/>
                    </Typography>

                </Stack>
                <Stack spacing={4}>
                    <Typography variant={'h3'}>Документация</Typography>
                    <Stack>
                        <DocumentRow
                            name={'ПУБЛИЧНАЯ ОФЕРТА ПРЕДЛОЖЕНИЕ об оказании услуг .pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/528ebb26-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={444829}
                        />
                        <DocumentRow
                            name={'Лицензия №Л030 00114 77 00742582 от 30 10 2023 на Услуги связи предоставлению.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/3cc62bdd-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={627748}
                        />
                        <DocumentRow
                            name={'Лицензия №188531 от 07 12 21 на Услуги связи по передаче данных.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/3ed9b710-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={704062}
                        />
                        <DocumentRow
                            name={'Лицензия №174439 от 23 05 19 на оказание телематические услуги связи.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/590d04b9-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={8016551}
                        />
                        <DocumentRow
                            name={'Лицензия №173902 от 06 05 19 на услуги связи для целей кабельного.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/4724d481-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={703196}
                        />
                        <DocumentRow
                            name={'Правила оказания услуг связи для целей телевизионного вещания и.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/41439bad-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={559648}
                        />
                        <DocumentRow
                            name={'Постановление об утверждении правил оказания телематических услуг.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/43085111-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={608441}
                        />
                        <DocumentRow
                            name={'Политика защиты и обработки персональных данных.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/de74e591-9b18-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={293296}
                        />
                        <DocumentRow
                            name={'Федеральный закон от 27 07 2006 №152 ФЗ О персональных данных.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/4975e260-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={793360}
                        />
                        <DocumentRow
                            name={'Федеральный закон от 07 07 2003 №126 ФЗ О связи.pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/4d090907-9a5c-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={1442759}
                        />
                        <DocumentRow
                            name={'Согласие посетителя сайта на обработку персональных данных .pdf'}
                            // typeFile={'.PDF'}
                            link={`${domain}3000/assets/docs/f2f8f9ff-9b18-11ee-a300-22044dd8e4b0.pdf`}
                            sizeFile={212683}
                        />
                        <Divider
                            sx={{width: "100%", borderTop: '1px solid', borderColor: palette.grey['200']}}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
};
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/"

    >

    </Link>
];
export default Uric;