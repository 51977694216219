import React, {useEffect, useReducer, useState} from 'react';
import {
    Box,
    Button,
    CardHeader, CircularProgress,
    Container,
    Grid,
    ImageList, ImageListItem,
    Link,
    Paper,
    Stack, Switch,
    TextField,
    Typography
} from "@mui/material";
import PageTitle from "../../shared/layout/PageTitle";
import ReactQuill, {Quill} from "react-quill";
import './CreatePost.css'
import DropFile from "../../features/DropFile";
import PostRow from "../../entities/news/PostRow";
import {formatDate, getCurrentDateTime} from "../../shared/helper/date";
import PreviewPost from "../../entities/news/PreviewPost";
import allEndpoints from "../../shared/http";
import {enqueueSnackbar} from "notistack";
import ModalLoadFile from "../../features/ModalLoadFile";
import Cookies from "js-cookie";

const CreatePost = () => {
    const FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = [
        'ProstoOne',
        'Roboto',
        'Manrope',
    ];
    Quill.register(FontAttributor, true);

    const fonts = ['ProstoOne', 'Roboto', 'Manrope'];
    const Font = Quill.import('formats/font');
    Font.whitelist = fonts;
    Quill.register(Font, true);
    const modules = {
        toolbar: [
            [{header: [3, false]}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{size: []}],
            [{font: fonts}], // Здесь используем пустой массив, чтобы Quill создал выпадающий список шрифтов
            [{align: ["right", "center", "justify"]}],
            [{list: "ordered"}, {list: "bullet"}],
            ["link", "image"],
            [{color: ["red", "#785412"]}],
            [{background: ["red", "#785412"]}]
        ]
    };
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font"
    ];
    const initialState = {
        title: '',
        subtitle: '',
        body: "",
        date: '',
        image_description: '',
        city: {id: 1, name: "Барабинск"},
        image: 8,
        images: [],
        documents: []
    };

    function eventReducer(prevState, action) {
        switch (action.type) {
            case 'updateField':
                return {
                    ...prevState,
                    [action.fieldName]: action.payload,
                };
            case 'addAdditionalFields':
                return {
                    ...prevState,
                    newField1: '',
                    newField2: '',
                };
            case 'updateBody':
                return {
                    ...prevState,
                    body: action.payload,
                };
            case 'loadEventFromCookies':
                return {
                    ...prevState,
                    ...action.payload,
                };
            default:
                return prevState;
        }
    }

    const [event, dispatch] = useReducer(eventReducer, initialState);
    const [body, setBody] = useState(event.body || '' );
    const handleProcedureContentChange = (content, delta, source, editor) => {
        setBody(content);
        // Вызываем действие для обновления code в объекте состояния
        dispatch({type: 'updateBody', payload: content});
    };
    const [icons, setIcons] = useState([]);
    const [files, setFiles] = useState([]);
    const [cites, setCites] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const handleChange = (e) => {
        const {name, value} = e.target;
        dispatch({type: 'updateField', fieldName: name, payload: value});
    };
    const dateNow = getCurrentDateTime()
    const stringDate = formatDate(dateNow)
    const saveEventToCookies = (eventData) => {
        Cookies.set('eventData', JSON.stringify(eventData));
    };
    const loadEventFromCookies = () => {
        const eventData = Cookies.get('eventData');
        console.log(eventData)
        if (eventData) {
            dispatch({ type: 'loadEventFromCookies', payload: JSON.parse(eventData) });
            setBody(JSON.parse(eventData)?.body)
        }
    };
    const handleImageClick = (selectedImage) => {
        // Проверить, есть ли выбранное изображение уже в массиве images
        const imageExists = event.images.some(image => image.id === selectedImage.id);

        if (imageExists) {
            // Если изображение уже есть, удалите его из массива
            const updatedImages = event.images.filter(image => image.id !== selectedImage.id);
            dispatch({ type: 'updateField', fieldName: 'images', payload: updatedImages });
        } else {
            // Если изображение не существует, добавьте его в массив
            const updatedImages = [...event.images, selectedImage];
            dispatch({ type: 'updateField', fieldName: 'images', payload: updatedImages });
        }
    };
    const handleFileClick = (selectedFile) => {
        // Проверить, есть ли выбранное изображение уже в массиве images
        const fileExists = event.documents.some(document => document.id === selectedFile.id);

        if (fileExists) {
            // Если изображение уже есть, удалите его из массива
            const updateDocuments = event.documents.filter(document => document.id !== selectedFile.id);
            dispatch({ type: 'updateField', fieldName: 'documents', payload: updateDocuments });
        } else {
            // Если изображение не существует, добавьте его в массив
            const updateDocuments = [...event.documents, selectedFile];
            dispatch({ type: 'updateField', fieldName: 'documents', payload: updateDocuments });
        }
    };

    const addPost = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.news.addPost(event)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                enqueueSnackbar('Новость добавлена', {variant: 'success'});
                Cookies.remove('eventData')
                dispatch({ type: 'loadEventFromCookies', payload: initialState });
            }
            else {
                console.error('Ошибка при добавлении новости:', response.statusText);
                enqueueSnackbar('Произошла ошибка, попробуйте позже', {variant: 'error'});

            }
        } catch (error) {
            if (error.response.status === 401) {
                // Ошибка авторизации (401)
                enqueueSnackbar('Необходима авторизация (токен умер)', { variant: 'error' });
            } else {
                // Другие ошибки сети
                console.error('Ошибка при добавлении новости:', error.message);
            }

        }finally {
            setIsLoading(false);
        }
    };
    const getCity = async () => {
        try {
            const response = await allEndpoints.city.getCities()
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                console.log('ok')
                setCites(response.data)
            } else {
                console.error('Ошибка при получении города:', response.statusText);
                enqueueSnackbar('Ошибка получения городов обновите страницу', {variant: 'error'});
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении города:', error.message);
            enqueueSnackbar('Ошибка получения городов обновите страницу', {variant: 'error'});
        } finally {

        }

    };
    const getIcon = async () => {
        try {
            const response = await allEndpoints.file.getImages()
            // Проверка HTTP-статуса ответа
            if (response.status === 201) {
                setIcons(response.data.result)
            } else {
                console.error('Ошибка при получении иконок:', response.statusText);
                enqueueSnackbar('Ошибка при получении иконок', {variant: 'error'});
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сервера', error.message);
            enqueueSnackbar('Ошибка сервера', {variant: 'error'});
        } finally {

        }

    };
    const getDocuments = async () => {
        try {
            const response = await allEndpoints.file.getDocument()
            // Проверка HTTP-статуса ответа
            if (response.status === 201) {
                if (response.data.result){
                    setFiles(response.data.result)
                }
            } else {
                console.error('Ошибка при получении документов:', response.statusText);
                enqueueSnackbar('Ошибка при получении документов', {variant: 'error'});
            }
        } catch (error) {
            console.error('Ошибка сервера', error.message);
            enqueueSnackbar('Ошибка сервера', {variant: 'error'});
        } finally {

        }

    };
    useEffect(() => {
        loadEventFromCookies();
        dispatch({type: 'updateField', fieldName: 'date', payload: dateNow})
        getCity();
        getIcon();
        getDocuments();
    }, []);
    useEffect(() => {
        // Функция для сохранения данных в cookies каждые 5 секунд
        const saveDataPeriodically = () => {
            saveEventToCookies(event);
            console.log('сохранение')
        };

        // Запуск сохранения данных каждые 5 секунд
        const intervalId = setInterval(saveDataPeriodically, 10000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [event]);

    return (
        <>
            <Container>
                <PageTitle
                    title={'Создание новостей'}
                    breadcrumbs={breadcrumbs}
                />
                <Grid container spacing={3} direction={'row'}>
                    <Grid item xs={12} md={4}>
                        <CardHeader title={'Детали'} subheader={'название, описание, содержание...'} sx={{padding: 0}}/>
                    </Grid>
                    <Grid item xs={12} md={8} direction={'row'}>
                        <Paper>
                            <Stack gap={3} padding={3} display={'flex'} flexDirection={'column'}>
                                <TextField
                                    name={'title'}
                                    label={'Название'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    value={event.title}
                                    onChange={handleChange}
                                />
                                <TextField
                                    multiline
                                    name={'subtitle'}
                                    rows={4}
                                    label={'Описание'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    value={event.subtitle}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name={'date'}
                                    label={'дата'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    value={event.date}
                                    onChange={handleChange}
                                />
                                <Stack display={'flex'} flexDirection={'column'} gap={'12px'}>
                                    <Typography variant="h6" gutterBottom>
                                        Содержание
                                    </Typography>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        value={body}
                                        onChange={handleProcedureContentChange}
                                    />
                                </Stack>
                                <Stack display={'flex'} flexDirection={'column'} gap={'12px'}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant="h6" gutterBottom>
                                            Основная картинка
                                        </Typography>
                                        <ModalLoadFile update={getIcon}/>
                                    </Stack>
                                    <TextField
                                        name={'image_description'}
                                        label={'Текст к картинке'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={event.image_description}
                                        onChange={handleChange}
                                    />
                                    <ImageList sx={{width: 500, height: 450}} cols={3} rowHeight={164}>
                                        {icons.map((item) => (
                                            <ImageListItem
                                                key={item.path}
                                                onClick={() => handleImageClick(item)}
                                                sx={{
                                                    border: event.images.some(image => image.id === item.id) ? '2px solid green' : 'none',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <img
                                                    srcSet={`https://185.200.241.2:${item.path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`https://185.200.241.2:${item.path}?w=164&h=164&fit=crop&auto=format`}
                                                    alt={'img'}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Stack>
                                <Stack display={'flex'} flexDirection={'column'} gap={'12px'}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant="h6" gutterBottom>
                                            Документация
                                        </Typography>
                                        <ModalLoadFile typeLoad={'document'} update={getDocuments}/>
                                    </Stack>
                                    <Stack>
                                        {files.map((item) => (
                                            <Stack
                                                direction={'row'}
                                                key={item.id}
                                                justifyContent={'space-between'}
                                                onClick={() => handleFileClick(item)}
                                            >
                                                <Typography variant={'subtitle2'}>{item.name}</Typography>
                                                <Switch checked={event.documents.some(document => document.id === item.id)}/>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardHeader title={'Дополнительно'} subheader={'SEO, поиск, теги...'} sx={{padding: 0}}/>
                    </Grid>
                    <Grid item xs={12} md={8} direction={'row'}>
                        <Paper>
                            <Stack gap={3} padding={3} display={'flex'} flexDirection={'column'}>
                                <TextField
                                    label={'meta title'}
                                    name={'meta_title'}
                                    variant={'outlined'}


                                    fullWidth={true}
                                />
                                <TextField
                                    multiline
                                    rows={4}
                                    name={'meta_description'}
                                    label={'meta description'}
                                    variant={'outlined'}

                                    fullWidth={true}
                                />
                                <TextField
                                    name={'meta_keywords'}
                                    label={'meta keywords'}
                                    variant={'outlined'}
                                    fullWidth={true}


                                />
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardHeader title={'Предпросмотр новости'}
                                    subheader={'Так это будет выглядеть на странице новостей'} sx={{padding: 0}}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <PostRow
                            title={event.title}
                            description={event.description}
                            date={event.date}
                        />
                    </Grid>
                    <Stack py={3} width={'100%'} direction={'row'} justifyContent={'space-evenly'}>
                        {
                            isLoading ? <CircularProgress size={2}/> :
                                <Button variant={'contained'} onClick={addPost}>Добавить новость</Button>
                        }
                        <PreviewPost
                            date={stringDate}
                            title={event.title}
                            body={event.body}
                            images={event.images}
                            documents={event.documents}
                        />
                    </Stack>
                    {/*<Grid item xs={12} md={4}>*/}
                    {/*    <CardHeader*/}
                    {/*        title={'Предпросмотр документов'}*/}
                    {/*        subheader={'Так это будет выглядеть на странице новостей'}*/}
                    {/*        sx={{padding: 0}}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} md={12}>*/}
                    {/*    <DocumentList/>*/}
                    {/*</Grid>*/}


                </Grid>
            </Container>
        </>
    );
};

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/admin">
        Админ панель
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/news"

    >
        Создание новостей
    </Link>
];

export default CreatePost;

