import React, {useContext, useState} from 'react';
import './Header.css'
import logo from "../../shared/images/spectrum logo.svg"
import {Button, Container, IconButton, Stack, Typography} from "@mui/material";
import {BiChevronDown} from "react-icons/bi";
import {Link} from "react-router-dom";
import AuthSidebar from "../../entities/AuthSidebar";
import NotificationSidebar from "../../entities/Notification/NotificationSidebar";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Avatar from "../../shared/svg/ReactIcon/Avatar";
import useMediaQuery from '@mui/material/useMediaQuery';
import HumberSidebar from "../../entities/HumberSidebar";
import MenuPopover from "../../features/MenuPopover";
import {Helmet} from "react-helmet-async";


const Header = observer(() => {
    const {store} = useContext(Context)
    const isMobile = useMediaQuery('(max-width:1023px)');
    const isPad = useMediaQuery('(min-width:900px)');
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
        <>
            <Helmet>
                {/* Описание структуры хлебных крошек для навигации */}
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Главная",
                                "item": "https://spektr-tv.su"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Тарифы",
                                "item": "https://spektr-tv.su/tariffs"
                            },
                            {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "Новости",
                                "item": "https://spektr-tv.su/news"
                            },
                            {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "Контакты",
                                "item": "https://spektr-tv.su/contact"
                            }
                        ]
                    })
                }}/>

                {/* Описание страницы для "Тарифы" */}
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Тарифы",
                        "description": "Узнайте о наших интернет и ТВ тарифах. Выберите оптимальный тариф с нужными опциями для вашего дома или бизнеса.",
                        "url": "https://spektr-tv.su/tariffs"
                    })
                }}/>

                {/* Описание страницы для "Новости" */}
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Новости",
                        "description": "Читайте последние новости компании Спектр. Узнайте о новых тарифах, обновлениях и событиях.",
                        "url": "https://spektr-tv.su/news"
                    })
                }}/>

                {/* Описание страницы для "Контакты" */}
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Контакты",
                        "description": "Свяжитесь с нами для получения консультаций, поддержки или дополнительной информации о наших услугах.",
                        "url": "https://spektr-tv.su/contact"
                    })
                }}/>
            </Helmet>
            <header className="header">
                <Container>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        paddingY={"20px"}
                    >

                        <Stack direction={"row"} gap={6}>
                            <Link to={'/'}>
                                <img src={logo} alt={'logo'}/>
                            </Link>
                            {isDesktop && <MenuPopover/>}
                        </Stack>
                        {
                            isMobile &&
                            <Stack direction={"row"} gap={1}>
                                {store.isAuth && <NotificationSidebar/>}
                                <HumberSidebar/>
                            </Stack>
                        }
                        {
                            isDesktop &&
                            <Stack direction={"row"} gap={6} alignItems={"center"}>
                                <nav>
                                    <Stack direction={"row"} gap={4}>
                                        <Link to="/tariffs" underline="none">
                                            <Typography>
                                                Тарифы
                                            </Typography>
                                        </Link>
                                        <Link to="/news" underline="none">
                                            <Typography>
                                                Новости
                                            </Typography>
                                        </Link>
                                        <Link to="/contact" underline="none">
                                            <Typography>
                                                Контакты
                                            </Typography>
                                        </Link>
                                        {
                                            store.isAdmin &&
                                            <Link to="/admin" underline="none">
                                                <Typography>
                                                    Админ панель
                                                </Typography>
                                            </Link>
                                        }
                                    </Stack>
                                </nav>
                                {store.isAuth &&
                                    <Stack direction={"row"} gap={1}>
                                        <Link to="/profile" underline="none">
                                            <Button
                                                variant={"text"}
                                                color={"secondary"}
                                                startIcon={<Avatar/>}
                                            >
                                                Константин К.
                                            </Button>
                                        </Link>
                                        <NotificationSidebar/>
                                    </Stack>
                                }
                                {
                                    !store.isAuth &&
                                    <Button variant={"contained"} size={"medium"}
                                            href={'http://lk.spektr-tv.su/cabinet/welcome/'}>
                                        Личный кабинет
                                    </Button>
                                    // <AuthSidebar
                                    //     openFilter={openFilter}
                                    //     onOpenFilter={handleOpenFilter}
                                    //     onCloseFilter={handleCloseFilter}
                                    // />
                                }
                            </Stack>
                        }
                    </Stack>
                </Container>
            </header>
        </>
    );
});

export default Header;