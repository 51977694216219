import React, {useContext, useEffect, useState} from 'react';
import './Admin.css'
import {Controller, useForm} from "react-hook-form";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import {yupResolver} from "@hookform/resolvers/yup";
import validationSchema from "./Admin/validationTariff";
import {Autocomplete} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import {Switch} from "@material-ui/core";
import {alpha, styled} from '@mui/material/styles';
import TariffCard from "../Component/Tariff/TariffCard";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import City from "../Component/City";
import {Route, Routes} from "react-router-dom";
import AddNews from '../Component/AddNews'
import AuthPanel from "../Component/AuthPanel";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const GreenSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#308E87FF",
        '&:hover': {
            backgroundColor: alpha("#308E87FF", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#308E87FF",
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const currencies = [
    {
        value: '1',
        label: 'Куйбышев',
    },
    {
        value: '2',
        label: 'Барабинск',
    },
    {
        value: '3',
        label: 'Алтайский Край',
    },
];
const Admin = observer(() => {
    const {
        control,
        handleSubmit,
        formState: {errors},
        setError,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [tariffTypes, setTariffTypes] = useState([])
    const [input, setInput] = useState({
        name: "",
        price: "",
        periodPerPay: "",
        description: "",
        title: "",
        cityId: "",
        TariffId: [],
    })
    const [cityValue, setCityValue] = useState('')
    const [openSnack, setOpenSnack] = React.useState(false);
    const [messageSnack, setMessageSnack] = React.useState('');
    const [tariffOption, setTariffOption] = useState([])
    const [cityOption, setCityOption] = useState([])
    const [selectCity, setSelectCity] = useState(1)
    const [post, setPost] = useState(null)
    const {store} = useContext(Context);
    console.log(store.isAuth)
    useEffect(() => {
        store.check()
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/get_tariff_types")
            .then(response => {
                if (response.status === 400) {
                }
            })
            .catch(
                data => {
                    setTariffOption(data.response.data.data)
                }
            )
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/get_cities")
            .then(response => {
                if (response.status === 400) {
                }
            })
            .catch(
                data => {
                    setCityOption(data.response.data.data)
                }
            )

    }, [])
    useEffect(() => {
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/get_tariffs", {id: selectCity})
            .then(response => {
                if (response.status === 200) {
                    setPost(response.data.data)
                }
            })
            .catch(e => {

                }
            )
            .finally(

            )
    }, [selectCity])
    const addTariff = (data) => {
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/add_tariff", (data))
            .then(response => {
                if (response.status === 200) {
                    setMessageSnack('Тариф добавлен')
                    setOpenSnack(true)
                }
            })
            .catch(e => {

                }
            )
            .finally(

            )
    };
    const removeTariff = (id) => {
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/remove_tariff", {id: id})
            .then(response => {
                if (response.status === 200) {
                    setMessageSnack('Тариф удален')
                    setOpenSnack(true)
                }
            })
            .catch(e => {

                }
            )
            .finally(

            )
    };
    const addCity = () => {
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/add_city", {name: cityValue})
            .then(response => {
                if (response.status === 200) {
                    setMessageSnack('Город добавлен')
                    setOpenSnack(true)
                }
            })
            .catch(e => {

                }
            )
            .finally(

            )
    };
    const label = {inputProps: {'aria-label': 'Switch demo'}};
    const handleChangeCityValue = (event) => {
        setCityValue(event.target.value);
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === 'price') {
            setInput({...input, [name]: parseFloat(value)})
        } else {
            setInput({...input, [name]: value})
        }
    }
    const handleChangeOption = (e) => {
        const {name, value} = e.target;
        if (tariffTypes.includes(parseInt(value))) {
            const newItems = tariffTypes.filter((n) => {
                return parseInt(n) !== parseInt(value)
            });
            setTariffTypes(newItems);
            setInput({...input, ['TariffId']: newItems})
        } else {
            const newItems = [...tariffTypes, parseInt(value)];
            setTariffTypes(newItems)
            setInput({...input, ['TariffId']: newItems})
        }

    }
    const handleClose = (event) => {
        setOpenSnack(false);
    };
    const handleSelect = (id) => {
        setSelectCity(id);
    };
    if (store.isAuth === false){
        return (<AuthPanel/>)
    }
    return (
        <div className='container'>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnack} autoHideDuration={3000}
                      onClose={handleClose} key={'top' + 'center'}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    {messageSnack}
                </Alert>
            </Snackbar>
            <div className={'admin__panel'}>
                <div className={'admin__block'}>
                    <h2>Добавить тариф</h2>
                    <form>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                value={input.name}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(errors.name)}
                                        className={Boolean(errors.name) ? 'animate__animated animate__headShake' : ""}
                                        fullWidth={true}
                                        type="text"
                                        label="Название тарифа"
                                        placeholder={'Комфортный'}
                                        value={input.name}
                                        onChange={handleChange}
                                        variant="standard"
                                        helperText={errors.name?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                value={input.title}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(errors.title)}
                                        className={Boolean(errors.title) ? 'animate__animated animate__headShake' : ""}
                                        fullWidth={true}
                                        value={input.title}
                                        onChange={handleChange}
                                        type="text"
                                        label="Заголовок"
                                        placeholder={'например До 100Мбит/сек'}
                                        variant="standard"
                                        helperText={errors.title?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="price"
                                control={control}
                                defaultValue=""
                                value={input.price}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(errors.price)}
                                        className={Boolean(errors.price) ? 'animate__animated animate__headShake' : ""}
                                        fullWidth={true}
                                        type="number"
                                        step="0.01"
                                        label="Цена"
                                        value={input.price}
                                        onChange={handleChange}
                                        variant="standard"
                                        placeholder={'например: 27.50'}
                                        helperText={errors.price?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="periodPerPay"
                                control={control}
                                defaultValue=""
                                value={input.periodPerPay}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(errors.periodPerPay)}
                                        className={Boolean(errors.periodPerPay) ? 'animate__animated animate__headShake' : ""}
                                        fullWidth={true}
                                        type="text"
                                        value={input.periodPerPay}
                                        onChange={handleChange}
                                        label="Период оплаты"
                                        placeholder={'либо день, либо месяц'}
                                        variant="standard"
                                        helperText={errors.periodPerPay?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                value={input.description}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={4}
                                        maxRows={4}
                                        error={Boolean(errors.description)}
                                        className={Boolean(errors.description) ? 'animate__animated animate__headShake' : ""}
                                        value={input.description}
                                        onChange={handleChange}
                                        fullWidth={true}
                                        type="text"
                                        label="Описание тарифа"
                                        variant={'outlined'}
                                        helperText={errors.description?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <div className={'tariffId'}>
                                {tariffOption.map((item) => (
                                    <div className={'tariffId__section'}>
                                        <p key={item.id}>{item.name}</p> <GreenSwitch {...label}
                                                                                      checked={tariffTypes.includes(parseInt(item.id))}
                                                                                      name={item.name}
                                                                                      value={parseInt(item.id)}
                                                                                      onChange={handleChangeOption}/>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Controller
                                name="cityId"
                                control={control}
                                defaultValue=""
                                value={input.cityId}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(errors.cityId)}
                                        className={Boolean(errors.cityId) ? 'animate__animated animate__headShake' : ""}
                                        fullWidth={true}
                                        value={input.cityId}
                                        onChange={handleChange}
                                        type="Select"
                                        select
                                        label="Название города"
                                        variant="standard"
                                        helperText={errors.cityId?.message}
                                    >
                                        {cityOption.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                    </form>
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={event => {
                            addTariff(input)
                        }}
                    >
                        Добавить
                    </Button>

                </div>
                <div className={'example__card'}>
                    <h2>Как это будет выглядеть</h2>
                    <p>Тариф будет добавлен в город: <strong>{input.cityId}</strong></p>
                    <TariffCard
                        price={input.price}
                        name={input.name}
                        title={input.title}
                        periodPerPay={input.periodPerPay}
                        description={input.description}
                        TariffType={tariffTypes}
                    />
                </div>
                <div className='cites'>
                    <h2>Добавить город</h2>
                    <TextField
                        fullWidth={true}
                        value={cityValue}
                        onChange={handleChangeCityValue}
                        type="text"
                        label="Название города"
                        placeholder={'Новосибирск'}
                        variant="outlined"

                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={addCity}
                        style={{marginTop: '15px', marginBottom: '15px'}}
                    >
                        Добавить
                    </Button>
                    <div className={"cites__post"}>
                        <h2>Удалить город</h2>
                        <ul className='cites__list'>
                            {cityOption.map((option) => (
                                <City name={option.name} id={option.id}/>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <AddNews/>
            <div className={'tariff'}>
                <div className="tariff__selector">
                    <p>Мой город</p>
                    <div className="tariff__filter">
                        {cityOption.map((option) => (
                            <p
                                key={option.id}
                                onClick={event => {
                                    handleSelect(option.id)
                                }}
                                style={option.id === selectCity ? {backgroundColor: '#9F73AB', color: 'white'} : null}

                            >{option.name}</p>
                        ))}
                    </div>
                </div>
                <div className="tariff__list">
                    {post?.map(item => {
                        return (
                            <>
                                <TariffCard
                                    key={item.id}
                                    delete={true}
                                    id={item.id}
                                    name={item.name}
                                    title={item.title}
                                    price={item.price}
                                    periodPerPay={item.periodPerPay}
                                    description={item.description}
                                    TariffType={item.TariffType}
                                />
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    );
});

export default Admin;