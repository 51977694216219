import React, {useEffect, useState} from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import allEndpoints from "../../shared/http";
import TariffList from "../../Component/Tariff/TariffList";

const TariffRecommendations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const handleGetTariffs = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.tariff.getTariffs(1)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                console.log('Результат получения тарифов:', response.data);
                setData(response.data.result)
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleGetTariffs()
    }, [])

    return (
        <Stack>
            <Stack spacing={3} maxWidth={"50%"}>
                <Typography variant={'h2'}>Наши тарифы</Typography>
                <Typography variant={'primaryMedium'}>Ознакомьтесь с нашими тарифами и выберите оптимальное решение для себя. Выберите тариф и оставьте заявку на подключение.</Typography>
            </Stack>
            <Grid container spacing={3} py={7}>
                <TariffList tariffs={data} isLoading={isLoading}/>
            </Grid>
        </Stack>
    );
};

export default TariffRecommendations;