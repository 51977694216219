import React from 'react';
import {
    Box, Breadcrumbs,
    Button,
    Container, Dialog, DialogContent, DialogTitle, Divider, Slide,
    Stack,
    Typography
} from "@mui/material";
import palette from "../../shared/theme/palette";
import DocumentList from "../../widgets/DocumentList";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewPost = ({ date, title, body, images, documents }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const mainImage = `https://185.200.241.2:${images[0] && images[0].path}`
    return (
        <>
            <Button
                onClick={handleClickOpen}
                variant={'contained'} color={'warning'}
            >
                Предпросмотр
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <DialogTitle id="scroll-dialog-title">
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography variant={'h6'}>
                            Просмотр
                        </Typography>
                        <Box display={'flex'} gap={3}>
                            <Button
                                variant={'outlined'}
                                color={'info'}
                                onClick={handleClose}
                            >Закрыть
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers={true} sx={{padding: 0}}>
                    <Container sx={{flex: 1}}>
                        <Stack pt={4} pb={5}>
                            <Stack pt={3} direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Typography
                                    variant={'h1'}
                                    className={'news__container'}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                >
                                    {date}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider
                            sx={{width: "100%", borderTop: '1px solid', borderColor: palette.grey['200']}}
                        />

                        <Stack
                            spacing={2}
                            className={'news__container'}
                            pt={4}
                        >

                            <Box height={'590px'} style={{backgroundColor: palette.grey['200']}}>
                                <img
                                    src={mainImage}
                                    style={{height: '100%', width: '100%', objectFit: 'cover'}}
                                />
                            </Box>
                            <Typography variant={'secondaryMedium'} color={palette.grey["400"]}>
                                Подпись к фото
                            </Typography>
                        </Stack>

                        <div className={'body__post__html'} dangerouslySetInnerHTML={{ __html: body }} />

                        <Stack py={6}>
                            <DocumentList
                                documents={documents}
                            />
                        </Stack>
                    </Container>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PreviewPost;