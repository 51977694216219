import React from 'react';
import {Grid, Typography} from "@mui/material";
import TariffCard from "./TariffCard";

const TariffList = ({tariffs, isLoading, isError}) => {

    if (isLoading) {
        return (
            <>
                <Grid item lg={3} md={3} xs={12}>
                    <TariffCard
                        isLoading={true}
                    />
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <TariffCard
                        isLoading={true}
                    />
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <TariffCard
                        isLoading={true}
                    />
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <TariffCard
                        isLoading={true}
                    />
                </Grid>
            </>
        )
    }
    if (isError) {
        return (
            <Grid item lg={6} md={6} xs={12}>
                <Typography variant={'h3'} color={'error'}>Ошибка получения тарифов</Typography>
            </Grid>
        )
    }
    return (
        <>
            {tariffs.map((tariff) => {
                return (
                    <Grid key={tariff.ID} item lg={3} md={3} xs={12}>
                        <TariffCard
                            title={tariff.title}
                            subtitle={tariff.subtitle}
                            period_per_pay={tariff.period_per_pay}
                            price={tariff.price}
                            short_description={tariff.short_description}
                            tariff_type={tariff.tariff_type}
                            id={tariff.ID}
                        />
                    </Grid>
                )
            })}
        </>
    );
};

export default TariffList;