import React, {useContext, useEffect, useState} from 'react';
import {Container, Grid, Skeleton, Stack, Typography} from "@mui/material";
import allEndpoints from "../../shared/http";
import TariffList from "./TariffList";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const BestTariff = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const {store} = useContext(Context)
    const handleGetBestTariffs = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.tariff.getTariffs(store.cityOption.id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                if (response.data.result){
                    setData(response.data.result)
                }
                console.log(response.data)
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
        }
        setIsLoading(false);
    };


    useEffect(() => {
        if(store.cityOption){
            handleGetBestTariffs()
        }
    }, [store.cityOption])
    return (
        <Container>
            <Stack pb={"80px"}>
                <Typography
                    variant={"h2"}
                    pt={"90px"}
                    pb={"50px"}
                    color={"#F8F8F8"}
                >
                    Наши Самые<br/> популярные тарифы
                </Typography>
                <Grid container spacing={3}>
                    <TariffList tariffs={data.slice(0,4)} isLoading={isLoading}/>
                </Grid>
            </Stack>
        </Container>
    );
});

export default BestTariff;