import moment from "moment";
import 'moment/locale/ru'

export function formatDate(inputDate) {
    // Преобразование даты в желаемый формат
    return moment(inputDate).locale('ru').format('DD MMMM YYYY');
}

export function getCurrentDateTime() {
    // Получение текущей даты и времени
    return moment().locale('ru').format('YYYY-MM-DDTHH:mm:ssZ');
}

//2023-08-23T15:02:00+03:00
//2023-09-12:00:28:11+07:00