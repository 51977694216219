import React from 'react';
import {IconButton} from "@mui/material";
import {BsFillTrashFill} from "react-icons/bs";
import {allContent} from "../lab/listContent";
import Box from "@mui/material/Box";

const DeleteContent = ({content = '', id}) => {
    const handleDelete = (id) => {
        if (content && allContent[content]) {
            allContent[content](id); // Передаем id в функцию удаления
        }
    };
    return (

        <IconButton onClick={event => allContent[content](id)}>
            <BsFillTrashFill style={{width: '20px', height:'20px'}}/>
        </IconButton>

    );
};

export default DeleteContent;