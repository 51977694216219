import React, {useEffect, useState} from 'react';
import {Button, Paper, Stack, Typography} from "@mui/material";
import Cookies from "js-cookie";
import 'animate.css';

const AcceptCookie = () => {
    const [isShow, setIsShow] = useState(false)
    const setCookie = () => {
        Cookies.set('acceptCookie', true)
        setIsShow(false)
    }
    useEffect(() => {
        setIsShow(true)
    }, []);
    if (!isShow) {
        return null
    }
    return (
        <Paper
            className={'animate__animated animate__fadeInUp'}
            sx={{
                position: 'fixed',
                bottom: {xs: '20px', md: '40px'},
                right: {xs: '20px', md: '40px'},
                maxWidth: '320px',
                bgcolor: 'primary.lighter'
            }}
        >
            <Stack spacing={3} padding={3}>
                <Stack>
                    <Typography variant={'h3'}>Мы используем файлы cookie</Typography>
                    <Typography variant={'primaryMedium'} color={'grey.400'}>Продолжая использовать сайт, вы
                        соглашаетесь с нашей
                        <a href={'https://www.969975-cv27771.tmweb.ru:3000/assets/docs/f2f8f9ff-9b18-11ee-a300-22044dd8e4b0.pdf'}
                           target={'_blank'}>
                            <Typography
                                component={'span'}
                                variant={'primaryBold'}
                                color={'primary.main'}
                            >
                                &nbsp;политикой конфиденциальности.
                            </Typography>
                        </a>
                    </Typography>
                </Stack>
                <Button variant={'contained'} onClick={setCookie}>Хорошо</Button>
            </Stack>
        </Paper>
    );
};

export default AcceptCookie;