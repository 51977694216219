import React from 'react';
import {Chip, Divider, Link, Stack, Typography} from "@mui/material";

const NewsLayout = ({ children, filterYear=[], selectYear, onClick }) => {
    return (
        <Stack spacing={3}>
            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={3}>
                {
                    filterYear.map((year) => (
                        <Link
                            key={year}
                            onClick={e => onClick(year)}
                            underline={selectYear === year ? 'always' : 'hover'}
                            sx={{cursor: 'pointer'}}
                            color={selectYear === year ? 'info.main' : 'inherit'}
                        >
                            {year}
                        </Link>
                    ))
                }
            </Stack>
            <Stack pb={10}>
                {
                    children.length > 0 ? children :
                        <Stack alignItems={'center'} pb={5}>
                            <Typography variant={'h4'}>Новостей нет</Typography>
                            <Typography variant={'body1'} color={'grey.600'}>Попробуйте изменить год публикации</Typography>
                        </Stack>
                }
                <Divider color={"#CCC"} sx={{height: 2}}/>
            </Stack>
        </Stack>
    );
};

export default NewsLayout;