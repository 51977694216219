import React from 'react';
import './Footer.css'
import {Link as RouterLink} from "react-router-dom";
import {Button, Container, Divider, Link, Stack, Typography} from "@mui/material";
import LogoFooter from "../../shared/svg/ReactIcon/LogoFooter";
import palette from "../../shared/theme/palette";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const currentYear = new Date().getFullYear();
    return (
        <footer itemScope itemType="http://schema.org/WPFooter">
            <section>
                <Container>
                    <Stack>
                        <Stack
                            direction={"row"}
                            pt={6}
                            pb={5}
                            justifyContent={"space-between"}
                            alignItems={"center"}>
                            <RouterLink to={'/'}>
                                <LogoFooter/>
                            </RouterLink>
                            <a href={'https://vk.com/spektr_sktv'} style={{textDecoration: 'none'}}>
                                <Typography variant={'body2'} color={'grey.0'}>Вконтакте</Typography>
                            </a>
                        </Stack>
                        <Divider color={"white"} sx={{height: 2, opacity: 0.2}}/>
                        <Stack
                            direction={isMobile ? "column" : "row"}
                            pt={isMobile ? 2 : 5}
                            color={'grey.400'}
                            pb={isMobile ? 4 : 13}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? 'flex-start' : "center"}
                            spacing={{xs: 10, md: 0}}
                            itemScope itemType="http://schema.org/Organization"
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={4}>
                                <Stack>
                                    <Link
                                        href={'TEL:+79133910808'}
                                        underline={'none'}
                                    >
                                        <Typography variant={"h3"} color={palette.grey["0"]} itemProp="telephone">
                                            +7 913 391 08 08
                                        </Typography>
                                    </Link>
                                    <Typography variant={"secondaryMedium"}>Справочная</Typography>
                                </Stack>
                                <Stack>
                                    <Link
                                        href={'TEL:+79137616068'}
                                        underline={'none'}
                                    >
                                        <Typography variant={"h3"} color={palette.grey["0"]} itemProp="telephone">
                                            +7 913 761 60 68
                                        </Typography>
                                    </Link>
                                    <Typography variant={"secondaryMedium"}>Справочная</Typography>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Link
                                    href={`mailto:spektr-sktv@mail.ru`}
                                    underline={'none'}
                                >
                                    <Typography
                                        itemProp="telephone"
                                        variant={"h3"} color={palette.grey["0"]}
                                    >
                                        spektr-sktv@mail.ru
                                    </Typography>
                                </Link>
                                <Typography variant={"secondaryMedium"}>По всем вопросам</Typography>
                            </Stack>
                        </Stack>
                        <Divider color={"white"} sx={{height: 2, opacity: 0.2}}/>
                        <Stack
                            direction={isMobile ? 'column-reverse' : "row"}
                            color={palette.grey["400"]}
                            py={isMobile ? 2 : 5}
                            justifyContent={"space-between"}
                            alignItems={!isMobile && 'center'}
                            spacing={{xs: 4, md: 0}}
                        >
                            <Typography variant={"secondaryMedium"}>© {currentYear}, ООО СКТВ «Спектр»</Typography>
                            <Stack
                                direction={isMobile ? 'column' : "row"}
                                alignItems={isMobile && 'flex-start'}
                                spacing={{xs: 2, md: 4}}
                            >
                                <RouterLink to={'/doc'}>
                                    <Typography variant={'body2'} color={'inherit'}>Юридическая информация</Typography>
                                </RouterLink>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </section>
        </footer>
    );
};

export default Footer;