import admin from "./endpoints/admin"
import tariff from "./endpoints/tariff"
import news from "./endpoints/news"
import auth from "./endpoints/auth"
import city from "./endpoints/city"
import feedback from "./endpoints/feedback"
import file from "./endpoints/file"

const allEndpoints = {
    admin,
    tariff,
    news,
    auth,
    city,
    feedback,
    file
};
export default allEndpoints