import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Divider, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
import allEndpoints from "../shared/http";
import {BsFillTrashFill} from "react-icons/bs";
import AddCity from "../features/AddCity";

const CitesControl = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cites, setCites] = useState([]);

    const getCity = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.city.getCities()
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                console.log('ok')
                setCites(response.data)
            } else {
                console.error('Ошибка при получении города:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении города:', error.message);
            setIsError(true)
        } finally {
            setIsLoading(false);
        }

    };

    const deleteCity = async (id) => {
        try {
            const response = await allEndpoints.city.deleteCity({"id": id})
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                console.log('ok')
                getCity()
            } else {
                console.error('Ошибка при получении города:', response.statusText);

            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении города:', error.message);

        } finally {

        }

    };

    useEffect(() => {
        getCity()
    }, []);
    if (isLoading) {
        return (
            <Paper p={3}>
                <CircularProgress/>
            </Paper>
        )
    }
    return (
        <Paper>
            <Stack p={3} spacing={3}>
                <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'h5'}>Города</Typography>
                    <AddCity getCity={getCity}/>
                </Stack>
                <Stack>
                    <Divider/>
                    {cites.map((item) => {
                        return (
                            <Stack>
                                <Stack direction={'row'} py={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant={'body2'}>
                                        {item.name}
                                    </Typography>

                                    <IconButton size={"small"} onClick={event => deleteCity(item.id)}>
                                        <BsFillTrashFill
                                            width={'22px'}
                                            height={'22px'}
                                        />
                                    </IconButton>
                                </Stack>
                                <Divider/>
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>
        </Paper>
    );
};

export default CitesControl;