import * as yup from "yup";

export const schemaContact = yup.object().shape({
    phone: yup.string().transform(value => value.replace(/[^\d]/g, '')).min(11, "Пожалуйста заполните полностью").required("Не может быть пустым"),
    name: yup.string().required("Не может быть пустым"),
    city: yup.string(),
    acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required')
    // login: yup.string().email("Неправильный формат почты").required("Почта обязательна"),
    // password: yup.string().min(6, "6 символов минимально").required("Не может быть пустым"),
});

