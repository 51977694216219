import React from 'react';
import {Divider, Skeleton, Stack, Typography} from "@mui/material";
import TariffShortTypeLayout from "../../entities/tariff/TariffShortTypeLayout";
import palette from "../../shared/theme/palette";

const TariffRowsList = ({ isLoading, tariff_type }) => {


    if (isLoading){
        return (
            <>
                <TariffShortTypeLayout
                    isLoading={isLoading}
                    fullWight
                />
                <TariffShortTypeLayout
                    isLoading={isLoading}
                    fullWight
                />
            </>
        )
    }
    if (!tariff_type){
        return null
    }
    return (
        <>
            {tariff_type.map((row, index)=>{
                return(
                    <TariffShortTypeLayout
                        key={index}
                        title={row.title}
                        subtitle={row.subtitle}
                        fullWight
                        icon={row.icon_path}
                    />
                )
            })}
            <Divider sx={{width: "100%", borderColor: palette.grey['100']}}/>
        </>
    );
};

export default TariffRowsList;