import React, {useEffect, useReducer, useState} from 'react';
import PageTitle from "../../shared/layout/PageTitle";
import {
    Avatar,
    Button,
    CardHeader,
    Container,
    Grid,
    IconButton,
    Link, MenuItem,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import TariffCard from "../../Component/Tariff/TariffCard";
import {BsTrashFill} from "react-icons/bs";
import allEndpoints from "../../shared/http";
import Cookies from "js-cookie";
import {useSnackbar} from "notistack";
import ModalLoadFile from "../../features/ModalLoadFile";
import {useParams} from "react-router-dom";


const initialData = {
    ID: 14,
    price: 33.55,
    period_per_pay: "день",
    title: "Комбо люкс",
    subtitle: "+ IVI",
    short_description: "Тариф включает в себя ряд преимуществ и услуг, предназначенных для обеспечения вашей комфортной связи. Вы получаете доступ к" +
        "высокоскоростному интернету, который позволит вам без проблем работать, общаться и" +
        "развлекаться онлайн. Кроме того, тариф включает услуги кабельного ТВ, предлагающего" +
        "разнообразие каналов и программ для вашего просмотра. ",
    tariff_type: [
        {
            ID: 3,
            type: 1,
            type_name: "Интернет",
            name: "Интернет",
            description: [
                {
                    title: "Скорость",
                    body: "Вы сможете наслаждаться просмотром на любом устройстве, будь то смартфон, планшет, телевизор или компьютер. Сервис поддерживает все популярные платформы, включая Web, iOS, Android, Smart TV и STB.",
                },
                {
                    title: "Технология подключения",
                    body: "Подключение по технологии FTTB (Fiber-To-The-Building).",
                },
            ],
            title: "до 100 Мбит/с",
            subtitle: "Безлимитный интернет",
            icon: 1,
            icon_path: "path",
        },
        {
            ID: 1,
            type: 2,
            type_name: "Интерактивное TV",
            name: "1",
            description: [
                {
                    title: "Кроссплатформенность",
                    body: "Вы сможете наслаждаться просмотром на любом устройстве, будь то смартфон, планшет, телевизор или компьютер. Сервис поддерживает все популярные платформы, включая Web, iOS, Android, Smart TV и STB.",
                },
                {
                    title: "Гибкость вещания",
                    body: "Управляйте эфиром по своему усмотрению - поставьте эфир на паузу, отложите просмотр или настройте напоминания о важных передачах. Кроме того, у вас будет доступ к архиву вещания за предыдущую неделю продолжительностью до 14 дней, чтобы у вас хватило времени и на дела, и на любимые программы.",
                },
            ],
            title: "Интерактивное TV",
            subtitle: "до 120 каналов",
            icon: 1,
            icon_path: "path",
        },
        {
            ID: 2,
            type: 3,
            type_name: "Онлайн кинотеатры",
            name: "1",
            description: [
                {
                    title: "О кинотеатре",
                    body: "Откройте двери в мир развлечений с широким выбором фильмов и сериалов на любой вкус. С онлайн кинотеатром  «MEGOGO» вы сможете смотреть популярные фильмы и сериалы в любое время. Управляйте своими развлечениями и погружайтесь в захватывающий мир.",
                },
            ],
            title: "IVI",
            subtitle: "до 20 000 фильмов и сериалов",
            icon: 1,
            icon_path: "path",
        },
    ],
    city_id: 2,
};

const CreateTariff = () => {
    const id = useParams().id;
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGetTariff, setIsLoadingGetTariff] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleGetTariff = async () => {
        try {
            const response = await allEndpoints.tariff.getTariff(id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                const newData = {
                    ...response.data.res,
                    tariff_type: response.data.res.tariff_type === null ? [] : response.data.res.tariff_type,
                };
                setData(newData);
            } else {
                enqueueSnackbar('Ошибка при получении данных тарифа', {variant: 'error'});
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
        }

    };
    const [icons, setIcons] = useState([]);
    const [cites, setCites] = useState([]);
    const handleChange = (e) => {
        const {name, value} = e.target;
        const dataType = e.target.name

        let updatedValue;
        if (dataType === 'price') {
            updatedValue = parseFloat(value); // Преобразование в число
        } else {
            updatedValue = value; // Строка остается строкой
        }

        setData({
            ...data,
            [name]: updatedValue,
        });
    };
    const handleChangeNested = (e, type, field) => {
        const {value} = e.target;
        setData((prevData) => {
            const newData = {...prevData};
            const index = newData.tariff_type.findIndex((item) => item.type === type);
            if (index !== -1) {
                newData.tariff_type[index][field] = value;
            }
            return newData;
        });
    };
    const handleIconClick = (type, selectedIconID) => {
        setData((prevData) => {
            const newData = {...prevData};
            const index = newData.tariff_type.findIndex((item) => item.type === type);
            if (index !== -1) {
                newData.tariff_type[index]["icon"] = selectedIconID;
            }
            return newData;
        });
    };
    const handleChangeNestedNested = (e, typeIndex, descriptionIndex, field) => {
        const {value} = e.target;
        setData((prevData) => {
            const newData = {...prevData};
            const type = newData.tariff_type[typeIndex];
            if (type) {
                const description = type.description[descriptionIndex];
                if (description) {
                    description[field] = value;
                }
            }
            return newData;
        });
    };
    const handleAddDescription = (typeIndex) => {
        setData((prevData) => {
            const newData = {...prevData};
            const type = newData.tariff_type[typeIndex];
            if (type) {
                type.description.push({
                    title: '',
                    body: '',
                });
            }
            return newData;
        });
    };
    const handleRemoveDescription = (typeIndex, descriptionIndex) => {
        setData((prevData) => {
            const newData = {...prevData};
            const type = newData.tariff_type[typeIndex];
            if (type && type.description.length > descriptionIndex) {
                type.description.splice(descriptionIndex, 1);
            }
            return newData;
        });
    };
    const handleUpdateType = (type, typeData) => {
        setData((prevData) => {
            const newData = {...prevData};

            if (type === 'add') {
                // Добавить объект в массив tariff_type
                newData.tariff_type.push(typeData);
            } else if (type === 'remove') {
                // Удалить объект из массива tariff_type, если он существует
                const typeIndex = newData.tariff_type.findIndex(
                    (item) => item.type === typeData.type
                );
                if (typeIndex !== -1) {
                    newData.tariff_type.splice(typeIndex, 1);
                }
            }

            return newData;
        });
    };
    const handleToggleType = (typeRow) => {
        if (data.tariff_type.some((item) => item.type === typeRow.type)) {
            // Если объект типа уже существует, удаляем его
            handleUpdateType('remove', typeRow);
        } else {
            // В противном случае, добавляем его
            handleUpdateType('add', typeRow);
        }
    };

    const createTariff = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.tariff.postTariff(data)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                enqueueSnackbar('Тариф добавлен', {variant: 'success'});
            } else {
                console.error('Ошибка при добавление тарифа:', response.statusText);
                enqueueSnackbar('Ошибка при добавление тарифа, попробуйте еще раз', {variant: 'error'});
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            enqueueSnackbar('Ошибка при добавление тарифа, попробуйте еще раз', {variant: 'error'});
            setIsError(true)
        } finally {
            setIsLoading(false);
        }

    };
    const updateTariff = async () => {
        setIsLoading(true);
        console.log(data)
        try {
            const response = await allEndpoints.tariff.updateTariff(data)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                enqueueSnackbar('Тариф обновлен', {variant: 'success'});
            } else {
                console.error('Ошибка при обновлении тарифа:', response.statusText);
                enqueueSnackbar('Ошибка при обновлении тарифа, попробуйте еще раз', {variant: 'error'});
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            enqueueSnackbar('Ошибка при обновлении тарифа, попробуйте еще раз', {variant: 'error'});
            setIsError(true)
        } finally {
            setIsLoading(false);
        }

    };

    const getCity = async () => {
        try {
            const response = await allEndpoints.city.getCities()
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                console.log('ok')
                setCites(response.data)
            } else {
                console.error('Ошибка при получении города:', response.statusText);
                enqueueSnackbar('Ошибка получения городов обновите страницу', {variant: 'error'});
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении города:', error.message);
            enqueueSnackbar('Ошибка получения городов обновите страницу', {variant: 'error'});
        } finally {

        }

    };
    const getIcon = async () => {
        try {
            const response = await allEndpoints.file.getIcons()
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                setIcons(response.data)
            } else {
                console.error('Ошибка при получении иконок:', response.statusText);
                enqueueSnackbar('Ошибка при получении иконок', {variant: 'error'});
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сервера', error.message);
            enqueueSnackbar('Ошибка сервера', {variant: 'error'});
        } finally {

        }

    };
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        getCity();
        getIcon();
        if (id) {
            handleGetTariff()
        }
    }, []);
    return (
        <>
            <Container>
                <PageTitle
                    title={'Создание тарифа'}
                    breadcrumbs={breadcrumbs}
                />
                <Grid container spacing={3} direction={'row'}>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={2}>
                            <CardHeader title={'Детали'} subheader={'название, описание, содержание...'}
                                        sx={{padding: 0}}/>
                            <TariffCard
                                title={data.title}
                                subtitle={data.subtitle}
                                period_per_pay={data.period_per_pay}
                                price={data.price}
                                city_id={data.city_id}
                                tariff_type={data.tariff_type}
                            />
                            <Typography h5>Загрузить иконки</Typography>
                            <ModalLoadFile typeLoad={'icon'}/>
                            {/*<img*/}
                            {/*    src={`https://185.200.241.2:3000/assets/icons/49e0fb5a-6f29-11ee-9b6d-22044dd8e4b0.png`}*/}
                            {/*    alt={'картинка'}*/}
                            {/*    style={{width: '100%', height: 'auto'}}*/}
                            {/*/>*/}

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} direction={'row'}>
                        <Stack spacing={3}>
                            <Paper>
                                <Stack gap={3} padding={3} display={'flex'} flexDirection={'column'}>
                                    <TextField
                                        name={'title'}
                                        label={'Название'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={data.title}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        name={'subtitle'}
                                        label={'Подзаголовок'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={data.subtitle}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline
                                        rows={4}
                                        name={'short_description'}
                                        label={'Описание'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={data.short_description}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        name="city_id"
                                        label={'Город'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={data.city_id} // Устанавливаем значение из состояния
                                        onChange={handleChange}
                                        select
                                    >
                                        {cites.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Stack display={'flex'} flexDirection={'column'} gap={'12px'}>
                                        <Typography variant="h6" gutterBottom>
                                            Цена
                                        </Typography>
                                        <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                            <TextField
                                                name={'price'}
                                                label={'цена'}
                                                variant={'outlined'}
                                                fullWidth={true}
                                                value={data.price}
                                                type={"number"}
                                                onChange={handleChange}
                                            />
                                            <Typography variant="body1">
                                                -
                                            </Typography>
                                            <TextField
                                                name={'period_per_pay'}
                                                placeholder={'В год, месяц, день...'}
                                                label={'период оплаты'}
                                                variant={'outlined'}
                                                fullWidth={true}
                                                value={data.period_per_pay}
                                                onChange={handleChange}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Paper>
                            <Paper>
                                <Stack padding={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Добавьте / удалите типы в тариф
                                    </Typography>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        {
                                            typesTariff.map((typeRow) => (
                                                <Paper key={typeRow.ID}
                                                       sx={{backgroundColor: colorsByType[typeRow.type]}}>
                                                    <Stack p={3} direction={'row'} spacing={2} alignItems={'center'}>
                                                        <Typography variant={'body1'}>{typeRow.type_name}</Typography>
                                                        <Switch
                                                            color={'primary'}
                                                            checked={data.tariff_type.some((item) => item.type === typeRow.type)}
                                                            onChange={() => handleToggleType(typeRow)}
                                                        />
                                                    </Stack>
                                                </Paper>
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            </Paper>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack spacing={3}>
                            {data.tariff_type.map((type, index) => (
                                <Paper key={index} sx={{backgroundColor: colorsByType[type.type]}}>
                                    <Stack gap={3} padding={3} display={'flex'} flexDirection={'column'}>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography variant="h6" gutterBottom>
                                                {type.type_name}
                                            </Typography>
                                            {icons.map((icon) => {
                                                return (
                                                    <img
                                                        style={{
                                                            width: 24,
                                                            height: 24,
                                                            cursor: 'pointer',
                                                            border: data.tariff_type[index].icon === icon.ID && '1px solid red'
                                                        }}
                                                        key={icon.ID}
                                                        alt="А"
                                                        src={`https://www.969975-cv27771.tmweb.ru:${icon.path}`}
                                                        onClick={e => {
                                                            handleIconClick(type.type, icon.ID)
                                                        }}
                                                    />
                                                )
                                            })}
                                            {/*<Switch*/}
                                            {/*    color="primary"*/}
                                            {/*/>*/}


                                        </Stack>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Stack width={450} spacing={3}>
                                                <TextField
                                                    name={`tariff_type[${index}].title`}
                                                    label={`Title ${index + 1}`}
                                                    value={type.title}
                                                    onChange={(e) => handleChangeNested(e, type.type, "title")}
                                                    fullWidth
                                                    size={'small'}
                                                />
                                                <TextField
                                                    name={`tariff_type[${index}].subtitle`}
                                                    label={`Subtitle ${index + 1}`}
                                                    value={type.subtitle}
                                                    onChange={(e) => handleChangeNested(e, type.type, "subtitle")}
                                                    fullWidth
                                                    size={'small'}
                                                />
                                            </Stack>
                                            <Stack spacing={3} maxWidth={'800px'} width={'100%'}>
                                                {type.description.map((row, descriptionIndex) => (
                                                    <Stack spacing={3} direction={'row'} alignItems={'center'}
                                                           key={descriptionIndex} justifyContent={'space-between'}>
                                                        <Stack width={'100%'} spacing={1}>
                                                            <TextField
                                                                name={`tariff_type[${index}].description[${descriptionIndex}].title`}
                                                                label={`Description Title ${descriptionIndex + 1}`}
                                                                value={row.title}
                                                                onChange={(e) => handleChangeNestedNested(e, index, descriptionIndex, 'title')}
                                                                fullWidth
                                                                size={'small'}
                                                            />
                                                            <TextField
                                                                multiline
                                                                rows={4}
                                                                name={`tariff_type[${index}].description[${descriptionIndex}].body`}
                                                                label={`Description Body ${descriptionIndex + 1}`}
                                                                value={row.body}
                                                                onChange={(e) => handleChangeNestedNested(e, index, descriptionIndex, 'body')}
                                                                fullWidth
                                                                size={'small'}
                                                            />
                                                        </Stack>
                                                        <IconButton

                                                            onClick={() => handleRemoveDescription(index, descriptionIndex)}
                                                        >
                                                            <BsTrashFill/>
                                                        </IconButton>
                                                    </Stack>
                                                ))}
                                                <Button
                                                    variant={'contained'}
                                                    onClick={() => handleAddDescription(index)}
                                                >
                                                    Добавить описание
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            ))}
                        </Stack>
                    </Grid>
                    <Stack py={3} width={'100%'} direction={'row'} justifyContent={'center'} spacing={2}>
                        <Button variant={'contained'} onClick={updateTariff} color={'warning'}>Обновить тариф</Button>
                        <Button variant={'contained'} onClick={createTariff} color={'secondary'}>Создать тариф</Button>

                    </Stack>
                </Grid>
            </Container>
        </>
    );
};
const colorsByType = {
    1: "#CCE6F9",
    2: "#E4F7CC",
    3: "#FFE8CC",
};
const typesTariff = [
    {
        ID: 3,
        type: 1,
        type_name: "Интернет",
        name: "Интернет",
        description: [
            {
                title: "Скорость",
                body: "Вы сможете наслаждаться просмотром на любом устройстве, будь то смартфон, планшет, телевизор или компьютер. Сервис поддерживает все популярные платформы, включая Web, iOS, Android, Smart TV и STB.",
            },
            {
                title: "Технология подключения",
                body: "Подключение по технологии FTTB (Fiber-To-The-Building).",
            },
        ],
        title: "до 100 Мбит/с",
        subtitle: "Безлимитный интернет",
        icon: 1,
        icon_path: "path",
    },
    {
        ID: 1,
        type: 2,
        type_name: "Интерактивное TV",
        name: "1",
        description: [
            {
                title: "Кроссплатформенность",
                body: "Вы сможете наслаждаться просмотром на любом устройстве, будь то смартфон, планшет, телевизор или компьютер. Сервис поддерживает все популярные платформы, включая Web, iOS, Android, Smart TV и STB.",
            },
            {
                title: "Гибкость вещания",
                body: "Управляйте эфиром по своему усмотрению - поставьте эфир на паузу, отложите просмотр или настройте напоминания о важных передачах. Кроме того, у вас будет доступ к архиву вещания за предыдущую неделю продолжительностью до 14 дней, чтобы у вас хватило времени и на дела, и на любимые программы.",
            },
        ],
        title: "Интерактивное TV",
        subtitle: "до 120 каналов",
        icon: 1,
        icon_path: "path",
    },
    {
        ID: 2,
        type: 3,
        type_name: "Онлайн кинотеатры",
        name: "1",
        description: [
            {
                title: "О кинотеатре",
                body: "Откройте двери в мир развлечений с широким выбором фильмов и сериалов на любой вкус. С онлайн кинотеатром  «MEGOGO» вы сможете смотреть популярные фильмы и сериалы в любое время. Управляйте своими развлечениями и погружайтесь в захватывающий мир.",
            },
        ],
        title: "IVI",
        subtitle: "до 20 000 фильмов и сериалов",
        icon: 1,
        icon_path: "path",
    },
]
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/admin">
        Админ панель
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/admin/tariff"

    >
        Создание тарифа
    </Link>
];

export default CreateTariff;