import React from 'react';

const BurgerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 12H20" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M4 5H20" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M4 19H20" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
};

export default BurgerIcon;