

export default function Container(theme) {
    return {
        MuiContainer: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: '1400px', // для точки (min-width: 1200px)
                    },

                    // Другие стили для контейнера здесь...
                },
            }
        }
    }
}