import React, {useContext, useEffect, useState} from 'react';
import {BiChevronDown} from "react-icons/bi";
import {Button, Menu, MenuItem, Popover, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Cookies from "js-cookie";
import allEndpoints from "../shared/http";

const options = [
    {
        value: 1,
        label: 'Куйбевышев'
    },
    {
        value: 2,
        label: 'Бабрабинск'
    }
]

const MenuPopover = observer(() => {
    const [open, setOpen] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [options, setOptions] = useState([]);
    const {store} = useContext(Context)
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    const handleClose = (option) => {
        store.setCity(option)
        Cookies.set('city', JSON.stringify(option))
        setOpen(null);
    };
    const getObjectFromCookie = (key) => {
        const cookieValue = Cookies.get(key);
        if (cookieValue) {
            return JSON.parse(cookieValue);
        }
        return null;
    };
    const getCity = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.city.getCities()
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                setOptions(response.data)
                const retrievedObject = getObjectFromCookie('city');
                if (!retrievedObject) {
                    store.setCity(response.data[0]);
                }
            } else {
                console.error('Ошибка при получении города:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении города:', error.message);
            setIsError(true)
        }finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        getCity()
        // Получаем объект из куки
        const retrievedObject = getObjectFromCookie('city');
        if (retrievedObject) {
            store.setCity(retrievedObject);
        }
    }, []);
    return (
        <>
            <Button
                startIcon={<BiChevronDown/>}
                onClick={handleOpen}
            >
                {store.cityOption ? store.cityOption.name : 'Выбрать город'}
            </Button>
            <Menu
                keepMounted
                // disableScrollLock
                anchorEl={open}
                open={Boolean(open)}
                onClose={event => {setOpen(null)}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{elevation: 22}}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id} onClick={event => {
                        handleClose(option)
                    }}>
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
});

export default MenuPopover;