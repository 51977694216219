import React from 'react';

const LogoFooter = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="145" height="32" viewBox="0 0 145 32" fill="none">
            <path d="M14.5363 11.7103C14.5363 11.7103 2.07661 17.4888 0 17.4888V14.512C2.07661 14.512 14.5363 6.98247 14.5363 6.98247C14.5363 6.98247 26.996 0.000156403 29.0726 0.000156403V5.95373C26.996 5.95373 14.5363 11.7103 14.5363 11.7103Z" fill="#FF8A00"/>
            <path d="M14.5363 20.2897C14.5363 20.2897 2.07661 14.5112 0 14.5112V17.488C2.07661 17.488 14.5363 25.0175 14.5363 25.0175C14.5363 25.0175 26.996 31.9998 29.0726 31.9998V26.4184C26.996 26.4184 14.5363 20.2897 14.5363 20.2897Z" fill="#953600"/>
            <path d="M14.5363 18.551C14.5363 18.551 2.07661 22.6973 0 22.6973V19.7205C2.07661 19.7205 14.5363 13.8865 14.5363 13.8865C14.5363 13.8865 26.996 8.18544 29.0726 8.18544V14.139C26.996 14.139 14.5363 18.551 14.5363 18.551Z" fill="#0081DF"/>
            <path d="M14.5363 13.4485C14.5363 13.4485 2.07661 9.30225 0 9.30225V12.279C2.07661 12.279 14.5363 18.113 14.5363 18.113C14.5363 18.113 26.996 23.8141 29.0726 23.8141V17.8605C26.996 17.8605 14.5363 13.4485 14.5363 13.4485Z" fill="#79D800"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.7949 15.6365C37.7949 11.419 41.235 8 45.4785 8H48.4056C52.2765 8 55.4788 10.8448 56.0115 14.5455H52.2789C51.8011 12.8666 50.2479 11.6364 48.4056 11.6364H45.4785C43.2557 11.6364 41.4538 13.4273 41.4538 15.6365V16.3638C41.4538 18.5729 43.2557 20.3638 45.4785 20.3638H48.4056C50.2479 20.3638 51.8011 19.1336 52.2789 17.4547H56.0115C55.4788 21.1554 52.2765 24.0002 48.4056 24.0002H45.4785C41.235 24.0002 37.7949 20.5813 37.7949 16.3638V15.6365ZM70.3589 8.36364H62.6753H59.0164V11.2728V23.6366H62.6753V11.2728H70.3589V23.6366H74.0178V11.2728V8.36364H70.3589ZM105.85 8.36364H110.607L103.753 15.8376L110.607 23.6366H106.216L100.61 17.4547H98.1664V23.6366H94.5075V17.4547V14.5455V8.36364H98.1664V14.5455H100.493L105.85 8.36364ZM81.3355 14.5455H90.8486V17.4547H81.3355V20.7274H91.2145V23.6366H81.3355H77.6767V20.7274V17.4547V14.5455V11.2728V8.36364H81.3355H91.2145V11.2728H81.3355V14.5455ZM112.07 8.36364H117.924H121.583H127.437V11.2728H121.583V23.6366H117.924V11.2728H112.07V8.36364ZM129.999 8.36364H129.633V23.6366H133.292V18.5456H139.878C142.707 18.5456 145 16.2663 145 13.4546C145 10.643 142.707 8.36364 139.878 8.36364H133.292H129.999ZM139.716 15.6365H133.292V11.2728H139.716C140.928 11.2728 141.911 12.2496 141.911 13.4546C141.911 14.6596 140.928 15.6365 139.716 15.6365Z" fill="white"/>
        </svg>
    );
};

export default LogoFooter;