import React from 'react';
import {Button, Divider, Stack, Typography} from "@mui/material";
import palette from "../shared/theme/palette";
import DownloadArrow from "../shared/svg/ReactIcon/DownloadArrow";

const DocumentRow = ({ name, sizeFile, typeFile, link, }) => {
    function humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['КБ', 'МБ', 'ГБ', 'ТБ', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }
    return (
        <Stack
        >
            <Divider
                sx={{width: "100%", borderTop:'1px solid', borderColor: palette.grey['200']}}
            />
            <Stack
                direction={{xs: 'column', md: 'row'}}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={{ xs: 2, md: 3 }}
                px={{ xs: 0, md: 2 }}
                color={palette.grey['800']}
            >
                <Stack
                    direction={'row'}
                    spacing={{ xs: 1, md: 3 }}
                    maxWidth={{xs: '100%', md: '70%'}}
                    width={'100%'}
                >
                    <DownloadArrow/>
                    <Typography variant={'secondaryBold'}>
                        {name}{typeFile}
                    </Typography>
                </Stack>

                <Typography variant={'secondaryBold'} noWrap color={palette.grey['200']}>
                    {humanFileSize(sizeFile, true)}
                </Typography>
                <a href={link} download  target="_blank">
                    <Typography variant={'secondaryBold'} width={{xs: '100%', md: 'auto'}}>
                        Скачать
                    </Typography>
                </a>
            </Stack>
        </Stack>
    );
};

export default DocumentRow;