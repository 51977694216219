import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import palette from "../shared/theme/palette";
import PropTypes from "prop-types";
import TariffCard from "../Component/Tariff/TariffCard";

const ProfileSettingAccordion = ({ title, text, alternativeText, details, email}) => {
    const [isShow, setIsShow] = useState(false)

    const toggleShow = () => {
        setIsShow(!isShow)
    }
    return (
        <Accordion sx={{ backgroundColor: 'transparent' }}
                   expanded={isShow}
                   onChange={toggleShow}
        >
            <Stack mb={2} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Stack spacing={1}>
                    <Typography variant={'primaryMedium'}>
                        {title}
                    </Typography>
                    <Typography color={palette.grey["600"]} variant={'secondaryMedium'}>
                        {
                            isShow ? alternativeText : text
                        }
                    </Typography>
                </Stack>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{p: 0, m:0, display: 'inline-block', minHeight: '10px' }}
                >
                    <Button variant={'text'} color={'inherit'} onClick={toggleShow}>
                        {isShow ? 'Скрыть' : 'Редактировать'}
                    </Button>
                </AccordionSummary>
            </Stack>
            <AccordionDetails sx={{p: 0}}>
                {details}
            </AccordionDetails>
        </Accordion>
    );
};

ProfileSettingAccordion.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.element,
    email: PropTypes.string,
    alternativeText: PropTypes.string,
    text: PropTypes.string,
};

export default ProfileSettingAccordion;

