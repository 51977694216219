import React from 'react';
import PageTitle from "../../shared/layout/PageTitle";
import {Container, Grid, Link} from "@mui/material";
import AppWidgetSummary from "../../entities/AppWidgetSummary";
import CitesControl from "../../widgets/CitesControl";

const AdminDashboard = () => {
    return (
        <>
            <Container sx={{flex: 1}}>
                <PageTitle
                    subtitle={'Здесь отображается основная информация'}
                    breadcrumbs={breadcrumbs}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Новости"
                            total={714000}
                            color={'warning'}
                            icon={'arcticons:nextcloud-news'}
                            path={'/admin/post'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Тарифы"
                            total={714000}
                            color={'warning'}
                            icon={'solar:card-2-broken'}
                            path={'/admin/tariff'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CitesControl/>
                        {/*<AppWidgetSummary*/}
                        {/*    title="Пользователей"*/}
                        {/*    total={714000}*/}
                        {/*    color={'warning'}*/}
                        {/*    icon={'entypo:v-card'}*/}
                        {/*    path={'/admin/tariff'}*/}
                        {/*/>*/}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AdminDashboard;

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/admin" >
        Админ панель
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/news"

    >

    </Link>
];