import React from 'react';
import {Divider, Stack, Typography} from "@mui/material";
import palette from "../shared/theme/palette";
import DocumentRow from "../entities/DocumentRow";

const DocumentList = ( {documents= []} ) => {
    return (
        <Stack spacing={4}>
            <Typography variant={'h3'}>Документация</Typography>
            <Stack pb={5}>
                {documents.map((row)=>{
                    return(
                        <DocumentRow
                            key={row.id}
                            name={row.name}
                            sizeFile={row.size}
                            link={`https://185.200.241.2:${row.path}`}
                        />
                    )
                })}
                <Divider
                    sx={{width: "100%", borderTop:'1px solid', borderColor: palette.grey['200']}}
                />
            </Stack>
        </Stack>
    );
};

export default DocumentList;
