import React from 'react';

const Avatar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M18 21V19.3333C18 18.4493 17.6839 17.6014 17.1213 16.9763C16.5587 16.3512 15.7956 16 15 16H9C8.20435 16 7.44129 16.3512 6.87868 16.9763C6.31607 17.6014 6 18.4493 6 19.3333V21"
                stroke="#339AE5" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
            <path
                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                stroke="#339AE5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="#339AE5" strokeWidth="2"/>
        </svg>
    );
};

export default Avatar;