import React, {useEffect, useState} from 'react';
import SvgContact from "../shared/svg/SvgContact";
import {Button, Container, Link, Paper, Stack, TextField, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FaqImg from "../shared/svg/FAQImg";
import ContactForm from "../widgets/feedback/ContactForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContactOfficeSlider from "../widgets/ContactOfficeSlider/ContactOfficeSlider";
import OfficeCard from "../entities/OfficeCard/OfficeCard";


const Contact = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Container>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Контакты'}
                subtitle={'Ознакомьтесь с нашими тарифами и выберите оптимальное решение для себя. Выберите тариф и оставьте\n' +
                    '                    заявку на подключение.'}
            />
            <OfficeCard/>
            <ContactForm/>
        </Container>
    );
};

export default Contact;


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/contact"

    >
        Контакты
    </Link>
];