import * as yup from "yup";

const schema = yup.object().shape({
    // name: yup.string().required("Не может быть пустым"),
    // price: yup.string().required("Не может быть пустым"),
    // periodPerPay: yup.string().required("Не может быть пустым"),
    // description: yup.string().required("Не может быть пустым"),
    // title: yup.string().required("Не может быть пустым"),
    // tariffId: yup.string().required("Не может быть пустым"),
    // cityId: yup.string().required("Не может быть пустым"),
});

export default schema;