import React from 'react';

const Mark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17" stroke="#333333" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M7 7L17 17" stroke="#333333" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    );
};

export default Mark;