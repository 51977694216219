import React, {useState} from 'react';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    Divider,
    Link,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import palette from "../shared/theme/palette";
import './Settings.css';
import ProfileSettingAccordion from "../entities/ProfileSettingAccordion";

const Settings = () => {
    const email = 'kostet1970@mail.ru'
    const phone = '+7 905 996 80 08'
    return (
        <>

            <Container sx={{flex: 1}}>
                <PageTitle
                    title={'Настройки'}
                    breadcrumbs={breadcrumbs}
                />
                <Stack maxWidth={'888px'} width={'100%'} pb={8} spacing={2}>
                    <ProfileSettingAccordion
                        title={'Электронный адрес'}
                        text={email}
                        alternativeText={'Укажите адрес, к которому у вас есть постоянный доступ.'}
                        details={
                            <Stack alignItems={'flex-start'} pt={3} pb={2} spacing={2}>
                                <TextField
                                    label={'e-mail'}
                                    defaultValue={email}
                                    sx={{maxWidth:'450px', width:'100%'}}
                                />
                                <Button variant={'contained'} color={'primary'}>Сохранить</Button>
                            </Stack>
                        }
                    />
                    <ProfileSettingAccordion
                        title={'Мобильный телефон'}
                        text={'+7 905 996 80 08'}
                        alternativeText={'Укажите телефон, к которому у вас есть постоянный доступ.'}
                        details={
                            <Stack alignItems={'flex-start'} pt={3} pb={2} spacing={2}>
                                <TextField
                                    label={'Номер телефона'}
                                    defaultValue={phone}
                                    sx={{maxWidth:'450px', width:'100%'}}
                                />
                                <Button variant={'contained'} color={'primary'}>Сохранить</Button>
                            </Stack>
                        }
                    />
                    <ProfileSettingAccordion
                        title={'Пароль'}
                        text={'*****************'}
                        alternativeText={'Последняя смена пароля: 17 авг 2023.'}
                        details={
                            <Stack alignItems={'flex-start'} pt={3} pb={2} spacing={2}>
                                <TextField
                                    label={'Текущий пароль'}

                                    sx={{maxWidth:'450px', width:'100%'}}
                                />
                                <TextField
                                    label={'Новый пароль'}

                                    sx={{maxWidth:'450px', width:'100%'}}
                                />
                                <TextField
                                    label={'Повторите новый пароль'}

                                    sx={{maxWidth:'450px', width:'100%'}}
                                />
                                <Button variant={'contained'} color={'primary'}>Обновить</Button>
                            </Stack>
                        }
                    />
                </Stack>
            </Container>
        </>
    );
};

export default Settings;

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/profile">
        Профиль
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/settings"

    >
        Настройки
    </Link>
];
