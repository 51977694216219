import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import Store from "./Store";
import {HelmetProvider} from 'react-helmet-async';
import ThemeProvider from "./shared/theme/index";
import {SnackbarProvider} from 'notistack';

const store = new Store()

export const Context = createContext({
    store,
})

ReactDOM.render(
    <Context.Provider value={{
        store
    }}>
        <HelmetProvider>
            <ThemeProvider>
                <SnackbarProvider
                    maxSnack={4}
                    autoHideDuration={2500}
                    anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                >
                    <App/>
                </SnackbarProvider>
            </ThemeProvider>
        </HelmetProvider>
    </Context.Provider>,
    document.getElementById('root')
);

