import React, {useEffect, useState} from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import DropSvg from "../shared/svg/DropSVG";
import {useDropzone} from "react-dropzone";
import allEndpoints from "../shared/http";
import {enqueueSnackbar} from "notistack";

const DropFile = () => {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ))
    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
    const handleUpload = async () => {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file`, file);
        });

        try {
            const response = await allEndpoints.file.addImage(formData)
            if (response.status === 200) {
                // Обработка успешной загрузки
                console.log('Файлы успешно загружены на сервер');
                enqueueSnackbar('Картинка успешно загружена на сервер', { variant: 'success' });
            } else {
                // Обработка ошибки при загрузке
                console.error('Произошла ошибка при загрузке файлов');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке запроса:', error);
        }
    };
    return (
        <Stack display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Box
                {...getRootProps({className: 'dropzone'})}
                sx={{
                    border: '2px dashed #C4C4C4',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    backgroundColor: 'var(--greyBgColor)'
                }}
                padding={5}
            >
                <input {...getInputProps()} />
                <Stack
                    alignItems={'center'}
                    flexDirection={"column"}
                    gap={3}
                >
                    <Box maxWidth={'200px'} width={'100%'} height={'100%'}>
                        <DropSvg/>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        Перетащите или нажмите
                    </Typography>
                </Stack>
            </Box>
            <aside>
                <ul>{thumbs}</ul>
            </aside>
            <Button onClick={handleUpload}>Загрузить</Button>
        </Stack>
    );
};

export default DropFile;


const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};