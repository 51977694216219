import React from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, Paper} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DropFile from "./DropFile";
import DropImg from "./DropImg";
import DropIcon from "./DropIcon";

const ModalLoadFile = ({ update, typeLoad='img' }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        update();
    };
    const isMobile = useMediaQuery('(max-width:800px)');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', md: 'auto'}
    };
    const componentByType = {
        img: <DropImg />,
        icon: <DropIcon />,
        document: <DropFile />,
    };
    return (
        <>
            <Button
                variant={"contained"}
                sx={{boxShadow: 'none'}}
                onClick={handleOpen}
                color={"primary"}
            >
                Добавить
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                keepMounted={true}
            >
                <Box sx={style}>
                    <Paper sx={{p: 4}}>
                        {componentByType[typeLoad]}
                    </Paper>
                </Box>
            </Modal>
        </>
    );
};

export default ModalLoadFile;