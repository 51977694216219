import React from 'react';
import './Instructions.css'

const Instructions = () => {
    return (
        <div className='container'>
            <h1 className='instructions__tittle'>Настройка IPTVPORTAL на телевизорах SmartTV</h1>
        </div>
    );
};

export default Instructions;