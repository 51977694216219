import React from 'react';
import {Box, Button, Paper, Stack, TextField, Typography} from "@mui/material";
import Modal from "@mui/material/Modal";
import allEndpoints from "../shared/http";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
};
const AddCity = ({ getCity }) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addCity = async () => {
        try {
            const response = await allEndpoints.city.addCity({'name': value})
            // Проверка HTTP-статуса ответа
            if (response.status === 201) {
                setValue('')
                handleClose()
                getCity()
            } else {
                console.error('Ошибка при добавление города:', response.statusText);

            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при добавление города:', error.message);

        }finally {

        }
    }

    return (
        <>
            <Button variant={'contained'} color={'primary'} onClick={handleOpen} size={'small'}>Добавить</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={style}>
                <Paper>
                    <Stack p={3} spacing={3}>
                        <Typography variant={'h5'}>Добавление города</Typography>
                        <TextField
                            variant={'outlined'}
                            label={'Название'}
                            onChange={event => {setValue(event.target.value)}}
                        />
                        <Stack direction={'row'} spacing={2}>
                            <Button color={'primary'} variant={'contained'} onClick={addCity}>Добавить</Button>
                            <Button color={'inherit'} variant={'text'} onClick={handleClose}>Отмена</Button>
                        </Stack>
                    </Stack>
                </Paper>
                </Box>
            </Modal>
        </>
    );
};

export default AddCity;