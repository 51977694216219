import axios from "../axios";

const endpoint = {
    getIcons: (data) => axios.get("icons"),
    deleteIcon: (data) => axios.delete("icon", data),
    addIcon: (data) => axios.post("icon", data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    getImages: (data) => axios.get("images"),
    deleteImage: (data) => axios.delete("image", data),
    addImage: (data) => axios.post("image", data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    getDocument: (data) => axios.get("documents"),
    deleteDocument: (data) => axios.delete("document", data),
    addDocument: (data) => axios.post("document", data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
}
export default endpoint;