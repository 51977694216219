import React from 'react';
import { Container, Stack,Typography} from "@mui/material";
import ReadyImg from "../../shared/svg/ReadyImg";
import useMediaQuery from "@mui/material/useMediaQuery";
import {FeedbackForm} from "../../features/FeedbackForm";


const ReadyToConnectSection = ({tariffName = null}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <section style={{backgroundColor: "white"}}>
            <Container>
                <Stack pt={10} pb={13}
                       px={isMobile ? 2 : 6}
                       direction={isMobile ? 'column-reverse' : 'row'}
                       alignItems={'center'} justifyContent={'space-between'}
                       spacing={isMobile && 3}
                >
                    <Stack spacing={5} maxWidth={550} pt={{xs: 3}} alignItems={!isMobile && "flex-start"}>
                        <Stack spacing={3}>
                            <Typography variant={'h2'}>
                                {
                                    tariffName ? `Готовы подключиться‎ к тарифу ${tariffName}?` :
                                        'Готовы подключиться‎?'
                                }
                            </Typography>
                            <Typography variant={"body2"}>
                                Заполните простую форму и отправьте заявку.<br/>
                                Ожидайте контакта от наших менеджеров в ближайшее время!
                            </Typography>
                        </Stack>
                        <FeedbackForm  cityMode={true}/>
                    </Stack>
                    <ReadyImg
                        width={isMobile ? '100%' : '430px'}
                        height={isMobile ? 'auto' : '344px'}
                    />
                </Stack>
            </Container>
        </section>
    );
};

export default ReadyToConnectSection;