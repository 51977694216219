import React, {useEffect} from 'react';
import palette from "../shared/theme/palette";
import PageTitle from "../shared/layout/PageTitle";
import {Container, Link, Stack, Typography} from "@mui/material";


const styles = {
    marker: {
        color: '#0081DF', // Цвет цифр
        marginRight: '0.5rem',
    },
    stack: {
        '& ul': {
            listStyleType: 'none',
            paddingLeft: 4,
            '@media (max-width: 768px)': {
                paddingLeft: '1rem', // Отступ для мобильных устройств
            }
        },
        '& li': {
            paddingBottom: 1,
            '& span': {
                color: palette.grey['600'],
            },
            '&::before': {
                marginRight: 3,
                color: '#0081DF', // Цвет цифр
            },

        },
    },
    ul: {
        listStyleType: 'none',
        padding: 0,
        '& ul': {
            padding: 0,
            listStyleType: 'none'
        },
    },
};
const SpectrLink = () => (
    <Link href='https://spektr-tv.su'>https://spektr-tv.su</Link>
);
const Policy = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    })
    const increment = (parent, index) => {
        return `${parent ? `${parent}.` : ''}${index}`;
    };
    return (
        <>
            <Container sx={{flex: 1}}>
                <PageTitle
                    breadcrumbs={breadcrumbs}
                    title={'Политика конфиденциальности'}
                />
                <Stack spacing={{xs: 4, md: 8}}>
                    <Stack spacing={{xs: 4, md: 8}} maxWidth={888}>
                        <Stack spacing={3}>
                            <Typography variant={'h3'}>Политика в отношении обработки персональных данных
                                компании ООО СКТВ “СПЕКТР”</Typography>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 1)}</span>
                                            Общие положения
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            Настоящая политика обработки персональных данных
                                            составлена в соответствии с требованиями Федерального
                                            закона от 27.07.2006. №152-ФЗ «О персональных данных» и
                                            определяет порядок обработки персональных данных и меры
                                            по обеспечению безопасности персональных данных
                                            Оператора (далее – Оператор).
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('1', 1)}</span>
                                                Оператор ставит своей важнейшей целью и условием
                                                осуществления своей деятельности соблюдение прав и свобод
                                                человека и гражданина при обработке его персональных
                                                данных, в том числе защиты прав на неприкосновенность
                                                частной жизни, личную и семейную тайну.
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('1', 2)}</span>
                                                Настоящая политика Оператора в отношении обработки
                                                персональных данных (далее – Политика) применяется ко всей
                                                информации, которую Оператор может получить о посетителях
                                                веб-сайта <SpectrLink/>
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 2)}</span>
                                            Основные понятия, используемые в Политике
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 1)}</span>
                                                Автоматизированная обработка персональных данных –
                                                обработка персональных данных с помощью средств
                                                вычислительной техники;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 2)}</span>
                                                Блокирование персональных данных – временное
                                                прекращение обработки персональных данных (за
                                                исключением случаев, если обработка необходима для
                                                уточнения персональных данных);
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 3)}</span>
                                                Веб-сайт – совокупность графических и информационных
                                                материалов, а также программ для ЭВМ и баз данных,
                                                обеспечивающих их доступность в сети интернет по сетевому
                                                адресу сайта <SpectrLink/>
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 4)}</span>
                                                Информационная система персональных данных —
                                                совокупность содержащихся в базах данных персональных
                                                данных, и обеспечивающих их обработку информационных
                                                технологий и технических средств;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 5)}</span>
                                                Обезличивание персональных данных — действия, в
                                                результате которых невозможно определить без использования
                                                дополнительной информации принадлежность персональных
                                                данных конкретному Пользователю или иному субъекту
                                                персональных данных;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 6)}</span>
                                                Обработка персональных данных – любое действие
                                                (операция) или совокупность действий (операций),
                                                совершаемых с использованием средств автоматизации или
                                                без использования таких средств с персональными данными,
                                                включая сбор, запись, систематизацию, накопление, хранение,
                                                уточнение (обновление, изменение), извлечение,
                                                использование, передачу (распространение, предоставление,
                                                доступ), обезличивание, блокирование, удаление, уничтожение
                                                персональных данных;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 7)}</span>
                                                Обработка персональных данных – любое действие
                                                (операция) или совокупность действий (операций),
                                                совершаемых с использованием средств автоматизации или
                                                без использования таких средств с персональными данными,
                                                включая сбор, запись, систематизацию, накопление, хранение,
                                                уточнение (обновление, изменение), извлечение,
                                                использование, передачу (распространение, предоставление,
                                                доступ), обезличивание, блокирование, удаление, уничтожение
                                                персональных данных;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 8)}</span>
                                                Персональные данные – любая информация, относящаяся
                                                прямо или косвенно к определенному или определяемому
                                                Пользователю веб-сайта <SpectrLink/>
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 9)}</span>
                                                Пользователь – любой посетитель веб-сайта <SpectrLink/>
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 10)}</span>
                                                Предоставление персональных данных – действия,
                                                направленные на раскрытие персональных данных
                                                определенному лицу или определенному кругу лиц;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 11)}</span>
                                                Распространение персональных данных – любые действия,
                                                направленные на раскрытие персональных данных
                                                неопределенному кругу лиц (передача персональных данных)
                                                или на ознакомление с персональными данными
                                                неограниченного круга лиц, в том числе обнародование
                                                персональных данных в средствах массовой информации,
                                                размещение в информационно-телекоммуникационных сетях
                                                или предоставление доступа к персональным данным каким либо иным
                                                способом;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 12)}</span>
                                                Трансграничная передача персональных данных – передача
                                                персональных данных на территорию иностранного
                                                государства органу власти иностранного государства,
                                                иностранному физическому или иностранному юридическому
                                                лицу;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('2', 13)}</span>
                                                Трансграничная передача персональных данных – передача
                                                персональных данных на территорию иностранного
                                                государства органу власти иностранного государства,
                                                иностранному физическому или иностранному юридическому
                                                лицу;
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 3)}</span>
                                            Оператор может обрабатывать следующие
                                            персональные данные Пользователя
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 1)}</span>
                                                Фамилия, имя, отчество
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 2)}</span>
                                                Номер телефона;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 3)}</span>
                                                Адрес электронной почты;
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 4)}</span>
                                                Адрес проживания
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 5)}</span>
                                                Также на сайте происходит сбор и обработка обезличенных
                                                данных о посетителях (в т.ч. файлов «cookie») с помощью
                                                сервисов интернет-статистики (Яндекс Метрика и Гугл
                                                Аналитика и других).
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('3', 6)}</span>
                                                Вышеперечисленные данные далее по тексту Политики
                                                объединены общим понятием Персональные данные.
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 4)}</span>
                                            Цели обработки персональных данных
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('4', 1)}</span>
                                                Цель обработки персональных данных Пользователя —
                                                заключение, исполнение и прекращение гражданско-правовых
                                                договоров; предоставление доступа Пользователю к сервисам,
                                                информации и/или материалам, содержащимся на веб-сайте
                                                <SpectrLink/>; уточнение деталей заказа
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('4', 2)}</span>
                                                Обезличенные данные Пользователей, собираемые с
                                                помощью сервисов интернет-статистики, служат для сбора
                                                информации о действиях Пользователей на сайте, улучшения
                                                качества сайта и его содержания.
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 5)}</span>
                                            Правовые основания обработки персональных данных
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('5', 1)}</span>
                                                Срок обработки персональных данных является
                                                неограниченным. Пользователь может в любой момент
                                                отозвать свое согласие на обработку персональных данных,
                                                направив Оператору уведомление посредством электронной
                                                почты с пометкой «Отзыв согласия на обработку персональных
                                                данных».
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('5', 2)}</span>
                                                Оператор обрабатывает обезличенные данные о
                                                Пользователе в случае, если это разрешено в настройках
                                                браузера Пользователя (включено сохранение файлов «cookie»
                                                и использование технологии JavaScript
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 6)}</span>
                                            Порядок сбора, хранения, передачи и других видов
                                            обработки персональных данных
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            Безопасность персональных данных, которые обрабатываются
                                            Оператором, обеспечивается путем реализации правовых,
                                            организационных и технических мер, необходимых для
                                            выполнения в полном объеме требований действующего
                                            законодательства в области защиты персональных данных.
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('6', 1)}</span>
                                                Оператор обеспечивает сохранность персональных данных и
                                                принимает все возможные меры, исключающие доступ к
                                                персональным данным неуполномоченных лиц.
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('6', 2)}</span>
                                                Персональные данные Пользователя никогда, ни при каких
                                                условиях не будут переданы третьим лицам, за исключением
                                                случаев, связанных с исполнением действующего
                                                законодательства.
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('6', 3)}</span>
                                                В случае выявления неточностей в персональных данных,
                                                Пользователь может актуализировать их самостоятельно,
                                                путем направления Оператору уведомление на адрес
                                                электронной почты Оператора с пометкой «Актуализация
                                                персональных данных».
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('6', 4)}</span>
                                                Срок обработки персональных данных является
                                                неограниченным. Пользователь может в любой момент
                                                отозвать свое согласие на обработку персональных данных,
                                                направив Оператору уведомление посредством электронной
                                                почты с пометкой «Отзыв согласия на обработку персональных
                                                данных».
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 7)}</span>
                                            Трансграничная передача персональных данных
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('7', 1)}</span>
                                                Оператор до начала осуществления трансграничной
                                                передачи персональных данных обязан убедиться в том, что
                                                иностранным государством, на территорию которого
                                                предполагается осуществлять передачу персональных данных,
                                                обеспечивается надежная защита прав субъектов
                                                персональных данных.
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('7', 2)}</span>
                                                Трансграничная передача персональных данных на
                                                территории иностранных государств, не отвечающих
                                                вышеуказанным требованиям, может осуществляться только в
                                                случае наличия согласия в письменной форме субъекта
                                                персональных данных на трансграничную передачу его
                                                персональных данных и/или исполнения договора, стороной
                                                которого является субъект персональных данных.
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                            <Stack sx={styles.stack}>
                                <ul style={styles.ul}>
                                    {/* Уровень 1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium">
                                            <span style={styles.marker}>{increment('', 8)}</span>
                                            Трансграничная передача персональных данных
                                        </Typography>
                                    </li>

                                    <ul>
                                        {/* Уровень 1.1 */}
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('8', 1)}</span>
                                                Трансграничная передача персональных данных
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('8', 2)}</span>
                                                В данном документе будут отражены любые изменения
                                                политики обработки персональных данных Оператором.
                                                Политика действует бессрочно до замены ее новой версией.
                                            </Typography>
                                        </li>
                                        <li style={styles.li}>
                                            <Typography variant="primaryMedium" color="text.primary">
                                                <span style={styles.marker}>{increment('8', 3)}</span>
                                                Актуальная версия Политики в свободном доступе
                                                расположена в сети Интернет по адресу <SpectrLink/>
                                            </Typography>
                                        </li>
                                    </ul>
                                </ul>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </>
);
};
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>
,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/"

    >

    </Link>
];
export default Policy;