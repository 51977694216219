import allEndpoints from "../../../shared/http";



export const fetchNewsData = async (year, idCity, sort) => {
    try {
        const response = await allEndpoints.news.getNews(year, idCity, sort)
        return response.data;
    } catch (error) {
        console.error('Ошибка при загрузке новостей:', error);
        return {'result': []};
        // throw error;
    }
};
