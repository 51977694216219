import React, {useEffect} from 'react';
import './Offer.css'
import PageTitle from "../shared/layout/PageTitle";
import {Box, Container, Divider, Link, Stack, Typography} from "@mui/material";
import ContactForm from "../widgets/feedback/ContactForm";
import palette from "../shared/theme/palette";
import DocumentRow from "../entities/DocumentRow";


const styles = {
    marker: {
        color: '#0081DF', // Цвет цифр
        marginRight: '0.5rem',
    },
    stack: {
        '& ul': {
            listStyleType: 'none',
            paddingLeft: 4,
            '@media (max-width: 768px)': {
                paddingLeft: '1rem', // Отступ для мобильных устройств
            }
        },
        '& li': {
            paddingBottom: 1,
            '& span': {
                color: palette.grey['600'],
            },
            '&::before': {
                marginRight: 3,
                color: '#0081DF', // Цвет цифр
            },

        },
    },
    ul: {
        listStyleType: 'none',
        padding: 0,
        '& ul': {
            padding: 0,
            listStyleType: 'none'
        },
    },
};


const Offer = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    })
    const increment = (parent, index) => {
        return `${parent ? `${parent}.` : ''}${index}`;
    };
    return (
        <Container>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Публичная оферта'}
            />
            <Stack spacing={{ xs: 4, md: 8 }}>
                <Stack spacing={{ xs: 4, md: 8 }} maxWidth={888}>
                    <Stack spacing={3}>
                        <Typography variant={'primaryMedium'}>
                            В соответствии со ст. 437 Гражданского кодекса Российской Федерации данный документ (далее –
                            «Оферта») является официальным, публичным и безотзывным предложением ООО СКТВ «Спектр», ИНН
                            5452112252, ОГРН 1065471009900 (далее – «ОПЕРАТОР») заключить договор (далее – «ДОГОВОР») об
                            оказании телематических услуг связи и услуг, технологически неразрывно связанных с
                            телематическими
                            услугами связи, направленных на повышение их потребительской ценности, если для этого не
                            требуется
                            отдельной лицензии по предоставлению доступа к сети Интернет (далее – «Услуги») на указанных
                            ниже
                            условиях. Оферта адресована совершеннолетним лицам. Для возможности получения услуг абонента
                            должны
                            иметь оконечное оборудование. Оператор не предоставляет каких-либо услуг по настройке
                            оконечного
                            оборудования абонента, аппаратного и программного обеспечения абонента, не связанных с
                            предоставлением доступа к сети Интернет.
                        </Typography>
                        <Typography variant={'primaryMedium'}>
                            Акцептом признается ответ определенного абонента, которому адресована оферта, о ее принятии
                            (в
                            соответствии со ст. 438 Гражданского кодекса Российской Федерации). Акцепт оферты означает
                            согласие
                            абонента со всеми положениями настоящего предложения. Полным и безоговорочным акцептом
                            настоящей
                            оферты является подписанное и переданное абонентом уполномоченному сотруднику оператора
                            заявление.
                            Заявление является неотъемлемой частью оферты.Подписанием заявления, о котором указано в
                            п.1.5.
                            настоящего предложения, абонент выражает свое согласие на размещение оператором оборудования
                            связи в
                            местах общего пользования (общем имуществе) жилого дома, в котором предполагается оказание
                            услуг
                            связи абоненту.
                        </Typography>
                        <Typography variant={'primaryMedium'}>
                            Оператор оказывает услуги связи на основании лицензий, выданных Федеральной службой по
                            надзору в
                            сфере информационных технологий и массовых коммуникаций лицензия №173902 от 09.02.2017 г. на
                            оказание услуг связи для целей кабельного вещания, № 174439 от 23.05.2019 на оказание
                            телематических услуг связи. Срок действия оферты устанавливается на весь период действия
                            указанных лицензий. Оператор вправе вносить изменения в оферту в любое время без уведомления
                            абонента путем размещения новой редакции оферты на официальном сайте <Box component={"span"}
                                                                                                      color={'#339AE5'}>
                            оператора
                        </Box>
                        </Typography>
                    </Stack>
                    <Stack sx={styles.stack}>
                        <ul style={styles.ul}>
                            {/* Уровень 1 */}
                            <li style={styles.li}>
                                <Typography variant="primaryMedium" color="text.primary">
                                    <span style={styles.marker}>{increment('', 1)}</span> Предмет договора
                                </Typography>
                            </li>
                            <ul>
                                {/* Уровень 1.1 */}
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('1', 1)}</span>
                                        Оператор оказывает телематические услуги связи с предоставлением доступа к сети передачи данных (далее СПД) с использованием абонентской линии.
                                    </Typography>
                                </li>
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('1', 1)}</span>
                                        АБОНЕНТ обязуется использовать оказываемые ОПЕРАТОРОМ услуги только для личных целей, не связанных с осуществлением предпринимательской деятельности.
                                    </Typography>
                                </li>
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('1', 3)}</span>
                                        Договор, заключаемый между АБОНЕНТОМ и ОПЕРАТОРОМ, является публичным.
                                    </Typography>
                                </li>
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('1', 4)}</span>
                                        ОПЕРАТОР оказывает АБОНЕНТУ услуги связи, указанные Абонентом в заявлении, а АБОНЕНТ оплачивает оказываемые услуги связи в соответствии с условиями настоящего договора согласно выбранному тарифному плану.
                                    </Typography>
                                </li>
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('1', 5)}</span>
                                        Наименование, состав оказываемых услуг связи, адрес установки пользовательского (оконечного) оборудования, условия тарифного плана, а также иная существенная информация, указана в соответствующих услуге связи Приложениях, подписываемых между ОПЕРАТОРОМ и АБОНЕНТОМ.
                                    </Typography>
                                </li>
                            </ul>
                            {/* Уровень 2 */}
                            <li style={styles.li}>
                                <Typography variant="primaryMedium" color="text.primary">
                                    <span style={styles.marker}>{increment('', 2)}</span>Права и обязанности сторон
                                </Typography>
                            </li>
                            <ul>
                                {/* Уровень 2.1 */}
                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('2', 1)}</span>ОПЕРАТОР обязуется:
                                    </Typography>
                                </li>
                                <ul>
                                    {/* Уровень 2.1.1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.1', 1)}</span>
                                            На основании заявления АБОНЕНТА, при необходимости, провести техническое обследование индивидуального жилого строения на предмет возможности подключения к СПД ОПЕРАТОРА СВЯЗИ.
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.1', 1)}</span>
                                            В случае необходимости передать АБОНЕНТУ по акту приема-передачи дополнительное оборудование, необходимое для осуществления доступа к услугам ОПЕРАТОРА СВЯЗИ. С момента подписания акта приема-передачи и до истечения срока действия настоящего Договора оборудование передаётся АБОНЕНТУ во временное пользование.
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.1', 1)}</span>
                                            В случае необходимости передать АБОНЕНТУ по акту приема-передачи дополнительное оборудование, необходимое для осуществления доступа к услугам ОПЕРАТОРА СВЯЗИ. С момента подписания акта приема-передачи и до истечения срока действия настоящего Договора оборудование передаётся АБОНЕНТУ во временное пользование.
                                        </Typography>
                                    </li>
                                </ul>

                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('2', 2)}</span>ОПЕРАТОР вправе:
                                    </Typography>
                                </li>
                                <ul>
                                    {/* Уровень 2.1.1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.2', 1)}</span>
                                            Приостанавливать оказание услуг связи в случае нарушения АБОНЕНТОМ требований, предусмотренных настоящим договором, в том числе срока оплаты оказанных Услуг, а также в случаях, установленных законодательством РФ до устранения нарушений.
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.2', 1)}</span>
                                            Осуществлять ограничение отдельных действий АБОНЕНТА, если такие действия создают угрозу для нормального функционирования сети связи.
                                        </Typography>
                                    </li>
                                </ul>

                                <li style={styles.li}>
                                    <Typography variant="primaryMedium" color="text.primary">
                                        <span style={styles.marker}>{increment('2', 3)}</span>АБОНЕНТ обязуется:
                                    </Typography>
                                </li>
                                <ul>
                                    {/* Уровень 2.1.1 */}
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.3', 1)}</span>
                                            Своевременно и в полном объеме вносить плату за оказанные услуги связи, согласно выбранному тарифному плану.
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.3', 1)}</span>
                                            Препятствовать распространению спама и вредоносного программного обеспечения с его абонентского терминала.
                                        </Typography>
                                    </li>
                                    <li style={styles.li}>
                                        <Typography variant="primaryMedium" color="text.primary">
                                            <span style={styles.marker}>{increment('2.3', 1)}</span>
                                            В случае досрочного расторжения договора вернуть переданное по Акту приёма-передачи дополнительное оборудование ОПЕРАТОРУ в работоспособном состоянии и имеющим товарный вид без механических повреждений. В случае невыполнения данного условия АБОНЕНТ выплачивает ОПЕРАТОРУ стоимость оборудования, зафиксированную в Акте приёма-передачи.
                                        </Typography>
                                    </li>
                                </ul>
                            </ul>
                        </ul>
                    </Stack>
                    <Stack>
                        <Typography variant={'primaryMedium'}>
                            Адреса и реквизиты ООО СКТВ «Спектр»<br/>
                            Почтовый адрес: 632387, Новосибирская область, г. Куйбышев, ул. К.Либкнехта, 1<br/>
                            ИНН 5452112252/КПП 545201001<br/>
                            Банковские реквизиты:<br/>
                            Р./Сч.: 40702810325040000320<br/>
                            В банке НОВОСИБИРСКИЙ РФ АО "РОССЕЛЬХОЗБАНК"<br/>
                            К./сч.: 30101810700000000784<br/>
                            БИК 045004784<br/>
                            Тел. (38362) 51-451; 8-913-391-08-08<br/>
                            E-mail: spektr-sktv@mail.ru<br/>
                        </Typography>
                    </Stack>
                </Stack>
                <Stack spacing={4}>
                    <Typography variant={'h3'}>Документация</Typography>
                    <Stack>
                        <DocumentRow
                            name={'Условия оказания услуг'}
                            typeFile={'.PDF'}
                            link={'https://spektr-tv.su/docs/offer.docx'}
                        />
                        <Divider
                            sx={{width: "100%", borderTop: '1px solid', borderColor: palette.grey['200']}}
                        />
                    </Stack>
                </Stack>

                <ContactForm/>
            </Stack>
        </Container>
    );
};


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/"

    >

    </Link>
];
export default Offer;