import React from 'react';

const Telegram = () => {
    return (
        <svg width="270" style={{zIndex:1}} height="270" viewBox="0 0 270 270" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M91.3811 144.882L107.398 189.214C107.398 189.214 109.4 193.362 111.544 193.362C113.689 193.362 145.581 160.184 145.581 160.184L181.047 91.6824L91.9526 133.439L91.3811 144.882Z" fill="#C8DAEA"/>
            <path d="M112.618 156.244L109.544 188.921C109.544 188.921 108.257 198.934 118.267 188.921C128.277 178.909 137.859 171.188 137.859 171.188" fill="#A9C6D8"/>
            <path d="M91.6722 146.459L58.7254 135.724C58.7254 135.724 54.7879 134.127 56.0558 130.504C56.3168 129.757 56.8433 129.121 58.4183 128.029C65.7184 122.941 193.538 76.9991 193.538 76.9991C193.538 76.9991 197.147 75.7829 199.275 76.5918C199.802 76.7548 200.275 77.0548 200.648 77.461C201.021 77.8672 201.279 78.3651 201.396 78.9037C201.626 79.8551 201.722 80.8339 201.681 81.8118C201.671 82.6578 201.569 83.4419 201.491 84.6716C200.713 97.2322 177.416 190.976 177.416 190.976C177.416 190.976 176.022 196.462 171.029 196.65C169.801 196.689 168.579 196.481 167.433 196.038C166.288 195.595 165.244 194.926 164.363 194.071C154.563 185.641 120.692 162.878 113.207 157.872C113.038 157.757 112.896 157.607 112.79 157.432C112.684 157.258 112.617 157.062 112.593 156.859C112.488 156.332 113.062 155.678 113.062 155.678C113.062 155.678 172.041 103.253 173.61 97.7497C173.732 97.3233 173.273 97.113 172.656 97.2997C168.739 98.7408 100.832 141.625 93.3372 146.358C92.7977 146.521 92.2275 146.555 91.6722 146.459Z" fill="white"/>
        </svg>

    );
};

export default Telegram;