import axios from "axios";
import Cookies from "js-cookie";


const axiosInstance = axios.create({
    baseURL: "https://www.969975-cv27771.tmweb.ru:3000/",
});

axiosInstance.interceptors.request.use(
    (config) => {
        const authToken = Cookies.get("tokenAdmin");
        if (authToken) {
            config.headers.authorization = `Bearer ${authToken}`;
        }
        return config;
    }

);
// axiosInstance.interceptors.response.use((config)=>{
//     return config;
// },async (error) => {
//     const originalRequest = error.config;
//     const dataRefresh = {
//         "access": Cookies.get("token"),
//         "devInfo": "1",
//         "refresh": Cookies.get("SuperToken")
//     }
//     if (error.response.status === 500){
//         try {
//             const response = await axios.post("https://www.969975-cv27771.tmweb.ru:8080/auth/action_refresh", dataRefresh)
//             Cookies.set("token", response.data.data)
//             return axiosInstance.request(originalRequest)
//
//         }catch (e) {
//             console.log("Не авторизован")
//         }
//
//     }
// })

export default axiosInstance;