import React, {useContext, useState} from 'react';
import './Auth.css'
import {Grid, TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import {Context} from "../index";
import allEndpoints from "../shared/http";
import {Container} from "@mui/material";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const AuthPanel = observer(() => {
    const [login, setLogin] = useState(Cookies.get('login')||'');
    const [password, setPassword] = useState(Cookies.get('password')||'');
    const {store} = useContext(Context);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await allEndpoints.auth.sign_in({login: login, password: password})
        if (response.status === 200) {
            Cookies.set("tokenAdmin", response.data.result, { expires: 1 })
            store.setAdmin(true)
        } else {
            console.error('Ошибка при получении тарифов:', response.statusText);
        }
    }
    if (store.isAdmin) {
        return <Navigate to="/admin" />;
    }
    return (
        <Container>
            <div className={'auth__section'}>
                <div className={'auth__block'}>
                    <h2>Вход</h2>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <TextField
                                fullWidth={true}
                                type="text"
                                label="логин"
                                name={'login'}
                                variant={'outlined'}
                                value={login}
                                onChange={(event) => setLogin(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                            <TextField
                                fullWidth={true}
                                type="password"
                                label="пароль"
                                name={'login'}
                                variant={'outlined'}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Войти
                        </Button>
                    </form>
                </div>
            </div>
        </Container>
    );
});

export default AuthPanel;