import React, {useEffect, useState} from 'react';
import {Container, Link} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import NewsList from "../widgets/news/UI/NewsList";
import ButtonsSortNews from "../features/ButtonsSortNews";


const News = () => {

    return (
        <Container sx={{flex: 1}}>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Новости'}
                subtitle={'Будьте в центре происходящего и получайте свежую информацию о наших услугах и новых возможностях.'}
            />
            {/*<ButtonsSortNews/>*/}
            <NewsList/>
        </Container>
    );
};

export default News;

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" >
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/news"

    >
        Новости
    </Link>
];