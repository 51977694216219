import React, {useEffect, useState} from 'react';
import {Button, Stack} from "@mui/material";
import PostRow from "../../../entities/news/PostRow";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {fetchNewsData} from "../api/newsService";

const PreviewNews = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const newsData = await fetchNewsData(2023, 1, 'desc');
                setNews(newsData['result'] ? newsData['result'] : []);
                setIsLoading(false);
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <Stack position={"relative"}>
            {news.slice(0,2).map((post)=>{
                return(
                    <PostRow
                        key={post.id}
                        title={post.title}
                        date={post.date}
                        id={post.id}
                        subtitle={post.subtitle}
                    />
                )
            })}
            <Box
                sx={{
                    background: "linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%);",
                    width: "100%",
                    height: "250px",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Link to={'/news'} className={'noEffect'}>
                    <Button variant={"contained"}>Все новости</Button>
                </Link>
            </Box>
        </Stack>
    );
};

export default PreviewNews;