import React from 'react';
import {Button, Container, Stack, Link, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import SleepImg from "../shared/svg/SleepImg";


const Page404 = () => {
    return (
        <>
            <Container>
                <PageTitle
                    breadcrumbs={breadcrumbs}
                />
                <Stack pb={20} pt={10} direction={'row'} justifyContent={'space-between'}>
                    <Stack>
                        <Typography variant={'headline'} color={'#339AE5'}>Ошибка 404</Typography>
                        <Typography variant={'h1'}>ой, а такой страницы нет...</Typography>
                        <Typography variant={'primaryBold'}>Возможно, она была перемещена, или вы просто неверно указали
                            адрес страницы.</Typography>

                        <Button color={'primary'} variant={'contained'} href={'/'}>Перейти на главную</Button>

                    </Stack>
                    <SleepImg/>
                </Stack>
            </Container>
        </>
    );
};

export default Page404;

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>
];