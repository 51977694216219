import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Button, Link, Paper, Stack, Typography} from "@mui/material";
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import icon from '../../shared/images/Placemark.png'
//------------------------------------------------------------------------------------
const AddressRow = ({address, city, children}) => {
    return (
        <>
            <Stack spacing={1}>
                <Typography variant={'primaryBold'} color={'#ffff'}>{city}</Typography>
                <Typography variant={'secondaryMedium'}>{address}</Typography>
            </Stack>
            {children}
        </>
    )

}
//------------------------------------------------------------------------------------

const OfficeCard = observer(() => {
    const {store} = useContext(Context)
    const [office, setOffice] = useState(null);
    const mapRef = useRef(null);
    useEffect(() => {
        if (store.cityOption) {
            setOffice(store.cityOption.name)
        }
    }, [store.cityOption]);
    useEffect(() => {
        if (mapRef.current && office) {
            const newCenter = office === 'Куйбышев' ? [55.444507, 78.314525] : [55.357363, 78.339327];
            mapRef.current.setCenter(newCenter, 16); // Меняет центр карты и масштаб
        }
    }, [office]);
    const toggleOffice = (value) => {
        setOffice(value)
    }
    return (
        <Stack
            direction={{xs: 'column', md: "row"}} pb={10}
            sx={{
                flex: '0 0 100%',
                minWidth: '0',
                transform: 'translate3d(0, 0, 0)',
            }}
        >
            <Paper variant={'blueCard'} sx={{maxWidth: {xs: '100%', md: '35%'}, width: "100%"}}>
                <Stack p={4} justifyContent={"space-between"} height={"100%"} minHeight={{xs: '360px'}}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant={'h2'}>НАШИ ОФИСЫ</Typography>
                    </Stack>
                    <Stack spacing={3}>
                        <Stack alignItems={'flex-start'}>
                            <Stack
                                direction={'row'}
                                spacing={'4px'}
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.0',
                                    borderRadius: '4px',
                                    p: '4px 4px',
                                }}
                            >
                                <Button
                                    variant={office === 'Куйбышев' ? "contained" : 'text'}
                                    onClick={() => toggleOffice('Куйбышев')}
                                    color={'light'}
                                    size={'small'}
                                >
                                    Куйбышев
                                </Button>
                                <Button
                                    variant={office === 'Барабинск' ? "contained" : 'text'}
                                    onClick={() => toggleOffice('Барабинск')}
                                    color={'light'}
                                    size={'small'}
                                >
                                    Барабинск
                                </Button>
                            </Stack>
                        </Stack>
                        {
                            office === 'Куйбышев' ?
                                <AddressRow
                                    city={"г. Куйбышев"}
                                    address={'ул. Карла Либкнехта, 1 ОЦ «Альянс»'}
                                >
                                    <Stack spacing={2}>
                                        <Stack spacing={1} color={"rgba(255, 255, 255, 0.80)"}>
                                            <Typography variant={'secondaryMedium'}>
                                                Режим работы: понедельник-пятница с 09:00 до 18:00.
                                            </Typography>
                                            <Typography variant={'secondaryMedium'}>
                                                Перерыв на обед с 13:00 до 14:00.
                                            </Typography>
                                        </Stack>
                                        <Stack color={"rgba(255, 255, 255, 0.80)"}>
                                            <Link
                                                href={'TEL:+79137616068'}
                                                underline={'none'}
                                            >
                                                <Typography variant={'primaryBold'} color={'grey.0'}>
                                                    Тел: 8(913) 761-60-68
                                                </Typography>
                                            </Link>
                                        </Stack>
                                    </Stack>

                                </AddressRow> :
                                <AddressRow
                                    city={"г. Барабинск"}
                                    address={'ул. Ленина 192'}
                                >
                                    <Stack spacing={2}>
                                        <Stack spacing={1} color={"rgba(255, 255, 255, 0.80)"}>
                                            <Typography variant={'secondaryMedium'}>
                                                Режим работы: понедельник-пятница с 10:00 до 17:00.
                                            </Typography>
                                            <Typography variant={'secondaryMedium'}>
                                                Перерыв на обед с 13:00 до 14:00.
                                            </Typography>
                                        </Stack>
                                        <Stack color={"rgba(255, 255, 255, 0.80)"}>
                                            <Link
                                                href={'TEL:+79133910808'}
                                                underline={'none'}
                                            >
                                                <Typography variant={'primaryBold'} color={'grey.0'}>
                                                    Тел: 8(913) 391-08-08
                                                </Typography>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </AddressRow>

                        }
                    </Stack>
                </Stack>
            </Paper>
            <Box
                sx={{width: '100%', height: '500px'}}
            >
                <YMaps>
                    <Map
                        defaultState={{
                            center: office === 'Куйбышев' ? [55.444507, 78.314525] : [55.357363, 78.339327],
                            zoom: 16
                        }}
                        style={{width: '100%', height: '500px'}}
                        instanceRef={mapRef}
                    >
                        {/*<Placemark*/}
                        {/*    geometry={[55.444507, 78.314525]}*/}
                        {/*    options={{*/}
                        {/*        iconLayout: 'default#image',*/}
                        {/*        iconImageHref: icon, // Путь к изображению иконки*/}
                        {/*        iconImageSize: [30, 42], // Размеры иконки*/}
                        {/*        iconImageOffset: [-15, -42] // Смещение иконки*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Placemark
                            geometry={[55.444507, 78.314525]}
                        />
                        <Placemark geometry={[55.357363, 78.339327]}/>
                    </Map>
                </YMaps>
            </Box>
            {/*<iframe*/}
            {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1131.5242713046023!2d78.3138016583381!3d55.44438551648338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTXCsDI2JzM5LjgiTiA3OMKwMTgnNTMuNiJF!5e0!3m2!1sru!2sfi!4v1581264752887!5m2!1sru!2sfi"*/}
            {/*    width="100%"*/}
            {/*    height="500"*/}
            {/*    style={{border: 'none'}}*/}
            {/*/>*/}
        </Stack>
    );
});

export default OfficeCard;