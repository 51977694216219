import React from 'react';
import {Divider, Skeleton, Stack, Typography} from "@mui/material";
import palette from "../../shared/theme/palette";

const TariffShortTypeLayout = ({ title, subtitle, isLoading, divider=true, fullWight, icon }) => {
    return (
        <Stack width={fullWight && '100%'}>
            {divider && <Divider sx={{width: "100%", borderColor: palette.grey['100']}}/>}
            <Stack direction={"row"} sx={{width: "100%"}} alignItems={"center"} gap={"10px"} py={divider ? 1 : 0}>
                {
                    icon ? <img src={`https://www.969975-cv27771.tmweb.ru:${icon}`} style={{width: '24px', height: '24px'}}/>
                        :  <Skeleton height={"24px"} width={"24px"} animation={false}/>
                }
                <Stack>
                    <Typography variant={"secondaryBold"}>
                        {isLoading ?
                            <Skeleton width={120}/> : title}
                    </Typography>
                    <Typography variant={"caption"}>
                        {isLoading ?
                            <Skeleton width={'100%'}/> : subtitle}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TariffShortTypeLayout;