import React from 'react';
import {Container, Paper, Stack, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import RouterImg from "../shared/svg/RouterImg";
import palette from "../shared/theme/palette";

const RouterSection = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Stack pb={10} pt={5}>
            <Paper
                variant={"elevation"}
                sx={{
                    p: {xs: 3, md: "32px 40px 40px 40px"},
                    backgroundColor: "#339AE5",
                    boxShadow: "none"
                }}
            >
                <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    justifyContent={'space-between'}
                    alignItems={isMobile && 'center'}
                    spacing={{xs: 3, md: 0}}
                    color={palette.grey["0"]}
                >
                    <Stack spacing={3} maxWidth={isMobile ? '100%' : '55%'}>
                        <Typography variant={"h2"}>
                            Роутер
                        </Typography>
                        <Typography variant={"body2"} color={palette.white["600"]}>
                            Мы проведем кабель в вашу квартиру и подключим его к вашему компьютеру или Wi-Fi
                            роутеру, чтобы обеспечить доступ к домашнему интернету. Вы можете использовать свой
                            собственный роутер или *приобрести его у нас.
                        </Typography>
                        <Typography variant={"body2"} color={palette.white["800"]}>
                            Обязательно ли нужен роутер? Нет, но помните, что без роутера мы сможем подключить
                            только один компьютер через кабель.
                        </Typography>
                        <Typography variant={"body2"} color={palette.white["600"]}>
                            * Стоимость роутера узнавайте у оператора
                        </Typography>
                    </Stack>
                    <RouterImg
                        width={isMobile ? '100%' : '328px'}
                    />
                </Stack>
            </Paper>
        </Stack>

    );
};

export default RouterSection;