import React, {useContext, useState} from 'react';
import {Box, Button, Divider, Drawer, IconButton, Stack, Typography} from "@mui/material";
import Mark from "../shared/svg/ReactIcon/Mark";
import BurgerIcon from "../shared/svg/ReactIcon/BurgerIcon";
import AuthForm from "../features/AuthForm";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import Avatar from "../shared/svg/ReactIcon/Avatar";
import {Link} from "react-router-dom";
import {BiChevronDown} from "react-icons/bi";
import palette from "../shared/theme/palette";
import MenuPopover from "../features/MenuPopover";

const HumberSidebar = observer(() => {
    const [openFilter, setOpenFilter] = useState(false);
    const [isShowAuth, setIsShowAuth] = useState(false);

    const handleShowAuth = () => {
        setIsShowAuth(!isShowAuth)
    }
    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };
    const {store} = useContext(Context)
    return (
        <>
            <IconButton variant={"contained"} size={"medium"} onClick={handleOpenFilter}>
                <BurgerIcon/>
            </IconButton>
            <Drawer
                anchor="right"
                open={openFilter}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: {width: 300, border: 'none', overflow: 'hidden'},
                }}
                ModalProps={{
                    keepMounted: true
                }}
            >
                <Stack
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{px: 1, py: 2}}>
                        <MenuPopover/>
                        <IconButton onClick={handleCloseFilter}>
                            <Mark/>
                        </IconButton>
                    </Stack>
                    <Stack sx={{flex: 1}}>
                        <Divider color={"#CCC"} sx={{height: 2}}/>
                        {(isShowAuth && !store.isAuth) ?
                            <AuthForm onCloseFilter={handleCloseFilter}/>
                            :
                            <nav>
                                <Stack direction={"column"} gap={2} px={2} py={2} >
                                    <Link to="/tariffs" style={{textDecoration: 'none'}}>
                                        <Typography variant={'h1'} color={palette.grey['800']} onClick={handleCloseFilter}>Тарифы</Typography>
                                    </Link>
                                    <Link to="/news"  style={{textDecoration: 'none'}}>
                                        <Typography variant={'h1'} color={palette.grey['800']} onClick={handleCloseFilter}>НОВОСТИ</Typography>
                                    </Link>
                                    <Link to="/contact" style={{textDecoration: 'none'}}>
                                        <Typography variant={'h1'} color={palette.grey['800']} onClick={handleCloseFilter}>Контакты</Typography>
                                    </Link>
                                </Stack>

                            </nav>

                        }
                        <Stack px={3}>
                            <Button variant={"contained"} size={"medium"} href={'http://lk.spektr-tv.su/cabinet/welcome/'}>
                                Личный кабинет
                            </Button>
                        </Stack>
                    </Stack>
                    {/*{store.isAuth ?*/}
                    {/*    <>*/}
                    {/*        <Divider color={"#CCC"} sx={{height: 2}}/>*/}
                    {/*        <Stack py={2} px={1} direction={'row'} justifyContent={'space-between'}>*/}
                    {/*            <Link to="/profile" underline="none">*/}
                    {/*                <Button*/}
                    {/*                    variant={"text"}*/}
                    {/*                    startIcon={<Avatar/>}*/}
                    {/*                >*/}
                    {/*                    Константин К.*/}
                    {/*                </Button>*/}
                    {/*            </Link>*/}
                    {/*            <Button*/}
                    {/*                variant={"text"}*/}
                    {/*                color={"inherit"}*/}
                    {/*                onClick={event => {*/}
                    {/*                    store.setAuth(false)*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                Выйти*/}
                    {/*            </Button>*/}
                    {/*        </Stack>*/}
                    {/*    </> :*/}
                    {/*    <Box mb={3}>*/}
                    {/*        <Button onClick={handleShowAuth}>Войти</Button>*/}
                    {/*    </Box>*/}
                    {/*}*/}
                </Stack>
            </Drawer>
        </>
    );
});

export default HumberSidebar;