import React from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import Telegram from "../shared/svg/telegram";
import palette from "../shared/theme/palette";
import useMediaQuery from "@mui/material/useMediaQuery";

const ChatBotTelegram = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Stack
            my={8}
            p={isMobile ? 2 : 4}
            sx={{backgroundColor: palette.grey['800'], borderRadius: '4px'}}
            direction={isMobile ? 'column-reverse' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            overflow={'hidden'}
            position={'relative'}
        >
            <Stack alignItems={isMobile ? '' : 'flex-start'} zIndex={2} spacing={4}>
                <Stack maxWidth={734} spacing={2}>
                    <Typography variant={"h2"} color={palette.white['0']} pb={1}>чат-бот в telegram</Typography>
                    <Typography variant={"body2"} color={palette.white['600']}>Интегрированный Чат-бот в Telegram для вашего удобства!
                        Взаимодействуйте с ботом, чтобы управлять настройками и получать информацию.</Typography>
                    <Typography variant={"body1"} color={palette.white['800']}>Вы сможете получать рекомендованный платеж, изучать тарифный план,
                        просматривать активные услуги и проверять баланс.</Typography>
                </Stack>
                <Button sx={{boxShadow: 'none'}} color={'primary'} href={'https://t.me/SpectrePersonalAreaBot'} variant={'contained'}>Перейти в телеграмм</Button>
            </Stack>
            <Stack position={'relative'}>
                <Box
                    sx={{position: 'absolute',
                        width: 480,
                        height: 480,
                        borderRadius: 480,
                        backgroundColor: '#339AE5',
                        filter: 'blur(280px)',
                        right:'-240px',
                        top: '-74px',
                        zIndex: '0',
                    }}
                />
                <Box
                    sx={{position: 'absolute',
                        width: 480,
                        height: 480,
                        borderRadius: 480,
                        backgroundColor: '#339AE5',
                        filter: 'blur(180px)',
                        right:'-240px',
                        top: '-74px',
                        zIndex: '0',
                    }}
                />
                <Box
                    sx={{position: 'absolute',
                        width: 480,
                        height: 480,
                        borderRadius: 480,
                        backgroundColor: '#339AE5',
                        filter: 'blur(180px)',
                        zIndex: '0',
                    }}
                />
                <Telegram/>
            </Stack>
        </Stack>
    );
};

export default ChatBotTelegram;