import axios from "../axios";

const endpoint = {
    getTariffs: (idCity) => axios.get(`tariffs/${idCity}`),
    getTariff: (id) => axios.get(`tariff/${id}`),
    deleteTariff: (data) => axios.delete("tariff", {data: {ID: data}}),
    postTariff: (data) => axios.post("tariff", data),
    updateTariff: (data) => axios.put("tariff/edit", data),

    getTariffTypes: (data) => axios.get("tariff-types", data),
    deleteTariffType: (data) => axios.delete("tariff-type", data),
    postTariffType: (data) => axios.post("tariff-type", data),

    getBest: (idCity) => axios.get(`best/${idCity}`),
    deleteBest: (data) => axios.delete("best", data),
    postBest: (data) => axios.post("best", data),

}
export default endpoint;