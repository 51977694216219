import React, {useEffect, useState} from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './AddNews.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddNews = () => {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [file2, setFile2] = useState(null);
    const [fileName2, setFileName2] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [data, setData] = useState('');
    const [uploaded, setUploaded] = useState(false);
    const [news, setNews] = useState(null)
    const [openSnack, setOpenSnack] = React.useState(false);
    const [messageSnack, setMessageSnack] = React.useState('');
    const handleClose = (event) => {
        setOpenSnack(false);
    };
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };
    const handleFile2Change = (event) => {
        setFile2(event.target.files[0]);
        setFileName2(event.target.files[0].name);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }

    const handleBodyChange = (event) => {
        setBody(event.target.value);
    }

    const handleDataChange = (event) => {
        setData(event.target.value);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('document', file2);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('data', data);
        axios.post('https://www.969975-cv27771.tmweb.ru:8083/tools/add_new', formData, {
        }).then(res => {
            setUploaded(true);
            if (res.status === 200){
                setMessageSnack('Новость добавлена')
                setOpenSnack(true)
            }
        }).catch(err => {
            console.log(err);
        });
    }
    useEffect(()=>{
        axios.post('https://www.969975-cv27771.tmweb.ru:8083/tools/get_news',)
            .then(res => {
                setNews(res.data.data)
            }).catch(err => {
            console.log(err);
        });
    }, [])
    const deleteNew = (id) => {
      axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/remove_new", {id: id})
          .then(response =>{
              if (response.status === 200){
                  setMessageSnack('Новость удалена')
                  setOpenSnack(true)
              }
          })
    }
    return (
        <div className={'news__admin'}>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnack} autoHideDuration={3000}
                      onClose={handleClose} key={'top' + 'center'}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    {messageSnack}
                </Alert>
            </Snackbar>
            <div className={'add__news'}>
                <h2>Добавление новостей</h2>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                <input
                    accept="*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file" >
                    <Button variant="contained" color="primary" component="span" style={{marginBottom:'15px'}}>
                        Картинка
                        <CloudUploadIcon />
                    </Button>
                </label >
                <input
                    accept="*"
                    className={classes.input}
                    id="contained-button-file2"
                    type="file"
                    onChange={handleFile2Change}
                />
                <label htmlFor="contained-button-file2" >
                    <Button variant="contained" color="primary" component="span" style={{marginBottom:'15px'}}>
                        Документ
                        <CloudUploadIcon />
                    </Button>
                </label >
                </div>
                <TextField
                    style={{marginBottom:'15px'}}
                    id="file-name"
                    label="Прикрепленная картинка"
                    value={fileName}
                    variant="outlined"
                    fullWidth={true}
                    disabled
                />
                <TextField
                    style={{marginBottom:'15px'}}
                    id="file-name"
                    label="Прикрепленнай документ"
                    value={fileName2}
                    variant="outlined"
                    fullWidth={true}
                    disabled
                />
                <TextField
                    style={{marginBottom:'15px'}}
                    id="title"
                    label="Заголовок новости"
                    value={title}
                    fullWidth={true}
                    onChange={handleTitleChange}
                    variant="outlined"
                />
                <TextField
                    style={{marginBottom:'15px'}}
                    id="body"
                    label="Текст новости"
                    fullWidth={true}
                    value={body}
                    onChange={handleBodyChange}
                    variant="outlined"
                />
                <TextField
                    style={{marginBottom:'15px'}}
                    id="data"
                    label="Дата новости"
                    placeholder={'Формат DD.MM.YYYY (28.12.2023)'}
                    fullWidth={true}
                    value={data}
                    onChange={handleDataChange}
                    variant="outlined"
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleUpload}
                >Добавить</Button>
            </div>
            <div className={'control__news'}>
                <h2>Удаление новостей</h2>
                {news?.map((post)=>(
                    <div key={post.id} className={'delete_new'}>
                        <p>{post.data}</p>
                        <p>{post.title}</p>
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={event => {deleteNew(parseInt(post.id))}}
                        >Удалить</Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AddNews;