import React, {useContext, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './pages/Home';
import News from "./pages/News";
import SingleNews from "./pages/SingleNews";
import Header from "./widgets/header/Header";
import Footer from "./widgets/footer/Footer";
import './pages/SIngleNews.css'
import Contact from "./pages/Contact";
import Instructions from "./pages/Instructions";
import Admin from './pages/Admin'
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import Offer from "./pages/Offer";
import Uric from "./pages/Uric";
import Tariffs from "./pages/Tariffs";
import "simplebar/src/simplebar.css";
import SingleTariff from "./pages/SingleTariff";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Page404 from "./pages/Page404";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreatePost from "./pages/Admin/CreatePost";
import CreateTariff from "./pages/Admin/CreateTariff";
import Auth from "./pages/Auth";
import Policy from "./pages/Policy";
import allEndpoints from "./shared/http";
import Cookies from "js-cookie";
import ScrollToTop from "./shared/ui/ScrollToTop";
import {Stack, Typography} from "@mui/material";
import AcceptCookie from "./widgets/cookie/AcceptCookie/AcceptCookie";



const App = observer(() => {
    const {store} = useContext(Context);
    useEffect(()=>{
        if(Cookies.get("tokenAdmin")){
            store.setAdmin(true)
        }
    })
    return (
        <Router>
            <ScrollToTop/>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/tariffs" element={<Tariffs/>}/>
                <Route path="/tariff/:id" element={<SingleTariff/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/post/:id" element={<SingleNews/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/instructions" element={<Instructions/>}/>
                <Route path="/offer" element={<Offer/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path="/doc" element={<Uric/>}/>
                <Route path="/auth" element={<Auth/>}/>

                {/*Админ-роуты*/}
                {store.isAdmin ?
                    <Route path="/admin" element={<AdminDashboard/>}/> : null
                }
                {store.isAdmin ?
                    <Route path="/admin/tariff" element={<CreateTariff/>}/> : null
                }
                {store.isAdmin ?
                    <Route path="/admin/tariff/:id" element={<CreateTariff/>}/> : null
                }
                {store.isAdmin ?
                    <Route path="/admin/post" element={<CreatePost/>}/> : null
                }

                {/*404*/}
                <Route path="*" element={<Page404 />} />

            </Routes>
            <Footer/>
            <AcceptCookie/>
        </Router>
    );
})

export default App;
