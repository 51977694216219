import React from 'react';
import {Box, Button, Container, Divider, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import TariffRecommendations from "../widgets/tariff/TariffRecommendations";
import palette from "../shared/theme/palette";
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const Profile = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Container>
                <PageTitle
                    title={"ЛИЧНЫЙ КАБИНЕТ"}
                    subtitle={'Здесь вы можете контролировать и настраивать свои тарифы, следить за оплатами и управлять подключениями. Ваша связь и развлечения – под вашим полным контролем!'}
                />
                <Grid container spacing={3}>
                    <Grid item lg={8} md={7} xs={12}>
                        <Paper>
                            <Stack p={3} spacing={3}>
                                <Stack spacing={1} direction={"row"} alignItems={'center'}>
                                    <Typography variant={'primaryMedium'}>Ваш лицевой счет:</Typography>
                                    <Typography variant={'primaryBold'}>№S2201000002</Typography>
                                </Stack>

                                <Divider
                                    color={"rgba(0, 0, 0, 0.20)"}
                                    sx={{height: 2, backgroundColor: "rgba(0, 0, 0, 0.20)", my: 6}}
                                />

                                <Stack direction={isMobile ? 'column' : "row"} alignItems={!isMobile && 'center'} justifyContent={'space-between'}>
                                    <Stack spacing={4} direction={isMobile ? 'column' : "row"}>
                                        <Stack width={160}>
                                            <Typography
                                                variant={'secondaryBold'} color={palette.grey["400"]}>
                                                Баланс на 07.07.2023:
                                            </Typography>
                                            <Typography variant={'h3'}>400,33 ₽</Typography>
                                        </Stack>
                                        <Stack width={160}>
                                            <Typography
                                                variant={'secondaryBold'} color={palette.grey["400"]}>
                                                К оплате 07.07.2023:
                                            </Typography>
                                            <Typography variant={'h3'}>400,33 ₽</Typography>
                                        </Stack>
                                        <Stack width={160}>
                                            <Typography
                                                variant={'secondaryBold'} color={palette.grey["400"]}>
                                                Статус интернета
                                            </Typography>
                                            <Typography variant={'h3'} color={'#00B507'}>Активен</Typography>
                                        </Stack>
                                    </Stack>
                                    <Button variant={'contained'} color={'primary'}>К оплате</Button>
                                </Stack>

                                <Divider
                                    color={"rgba(0, 0, 0, 0.20)"}
                                    sx={{height: 2, backgroundColor: "rgba(0, 0, 0, 0.20)", my: 6}}
                                />

                                <Stack spacing={1} direction={"row"} alignItems={'center'}>
                                    <Typography p={'4px'} variant={'secondaryMedium'}>Ваш тариф:</Typography>
                                    <Button size={"small"} variant={'text'} color={'primary'}>Комбо люкс + MEGOGO</Button>
                                </Stack>

                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={5} xs={12}>
                        <Paper>
                            <Stack p={3} spacing={3}>
                                <Typography variant={"primaryBold"}>Фамилия Имя</Typography>
                                <Box display={'flex'} gap={1} flexDirection={'column'} alignItems={'flex-start'}>
                                    <Link to={'/settings'}>
                                        <Button variant={"text"} color={'primary'}>Настройки</Button>
                                    </Link>
                                    <Button variant={"text"} color={'primary'}>Выйти</Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item lg={8} md={7} xs={12}>
                        <Paper>
                            <Stack p={3} spacing={4}>
                                <Typography variant={"h3"}>
                                    Заявка на ремонт
                                </Typography>

                                <Stack spacing={1}>
                                    <Typography variant={'primaryMedium'} color={palette.grey['600']}>Ознакомьтесь с нашими тарифами и выберите оптимальное решение для себя. Выберите тариф и оставьте заявку на подключение.</Typography>
                                    <Stack spacing={2}>
                                        <TextField
                                            label={'Тема'}
                                            variant={'standard'}
                                        />
                                        <TextField
                                            label={'Текст обращения'}
                                            variant={'standard'}
                                        />
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={1}>
                                    <Button variant={'contained'} color={'primary'}>Отправить</Button>
                                    <Button variant={'text'} color={'inherit'}>Очистить</Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>

                <Divider
                    color={"rgba(0, 0, 0, 0.20)"}
                    sx={{height: 2, backgroundColor: "rgba(0, 0, 0, 0.20)", my: 6}}
                />

                <TariffRecommendations/>


            </Container>
        </>
    );
};

export default Profile;