import React from 'react';
import {Paper, Stack, Typography} from "@mui/material";

const NotificationItem = () => {
    return (
        <Paper  variant={"elevation"}>
            <Stack spacing={2} p={3}>
                <Typography>23 авг 2023</Typography>
                <Stack>
                    <Typography variant={"h3"} color={'#0081DF'}>Успешная оплата</Typography>
                    <Typography variant={"primaryMedium"} color={'#666'}>Ваш платеж был успешно принят. Спасибо за оплату. Ваш аккаунт обновлен.</Typography>
                </Stack>

            </Stack>
        </Paper>
    );
};

export default NotificationItem;