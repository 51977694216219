import React from 'react';

const ShortLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="48" viewBox="0 0 44 48" fill="none">
            <path d="M21.9998 17.5649C21.9998 17.5649 3.31065 26.2324 0.195801 26.2324V21.7673C3.31065 21.7673 21.9998 10.4733 21.9998 10.4733C21.9998 10.4733 40.6889 4.3869e-05 43.8038 4.3869e-05V8.93022C40.6889 8.93022 21.9998 17.5649 21.9998 17.5649Z" fill="#FF8A00"/>
            <path d="M21.9998 30.4351C21.9998 30.4351 3.31065 21.7676 0.195801 21.7676V26.2327C3.31065 26.2327 21.9998 37.5267 21.9998 37.5267C21.9998 37.5267 40.6889 48 43.8038 48V39.6279C40.6889 39.6279 21.9998 30.4351 21.9998 30.4351Z" fill="#953600"/>
            <path d="M21.9998 27.8257C21.9998 27.8257 3.31065 34.0449 0.195801 34.0449V29.5798C3.31065 29.5798 21.9998 20.8291 21.9998 20.8291C21.9998 20.8291 40.6889 12.2776 43.8038 12.2776V21.2078C40.6889 21.2078 21.9998 27.8257 21.9998 27.8257Z" fill="#0081DF"/>
            <path d="M21.9998 20.1723C21.9998 20.1723 3.31065 13.9531 0.195801 13.9531V18.4182C3.31065 18.4182 21.9998 27.169 21.9998 27.169C21.9998 27.169 40.6889 35.7204 43.8038 35.7204V26.7902C40.6889 26.7902 21.9998 20.1723 21.9998 20.1723Z" fill="#79D800"/>
        </svg>
    );
};

export default ShortLogo;