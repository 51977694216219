import React from 'react';
import "./Home.css"
import {Button, Container, Divider, Drawer, Grid, Paper, Skeleton, Stack, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import BestTariff from "../Component/Tariff/BestTariff";
import ReadyToConnectSection from "../widgets/feedback/ReadyToConnectSection";
import MainPic from "../shared/svg/MainPic";
import palette from "../shared/theme/palette";
import ChatBotTelegram from "../entities/ChatBotTelegram";
import useMediaQuery from "@mui/material/useMediaQuery";
import PostRow from "../entities/news/PostRow";
import FeedbackModal from "../entities/FeedbackModal";
import {Link} from "react-router-dom";
import PreviewNews from "../widgets/news/UI/PreviewNews";

const Home = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <main className="home">
            <section>
                <Container>
                    <Grid
                        container
                        direction={isMobile ? 'column-reverse' : 'row'}
                        spacing={2} mt={isMobile ? 2 : "120px"} mb={"160px"}>
                        <Grid item lg={6}>
                            <Stack gap={4}>
                                <Typography variant={"h1"}>
                                    быстрый и доступный интернет уже в твоем городе
                                </Typography>
                                <Typography variant={"body2"}>
                                    Мы предлагаем надежное подключение кабельного ТВ, интернета по технологии GPON и IP
                                    TV в
                                    частный сектор. Узнай больше о наших выгодных тарифах и оставь заявку на подключение
                                    прямо
                                    сейчас.
                                </Typography>
                                <Stack
                                    direction={isMobile ? 'column' : "row"}
                                    spacing={2}
                                >
                                    <FeedbackModal name={'Заявка на подключение'}/>
                                    <Link to={'/tariffs'} className={'noEffect'}>
                                        <Button variant={"outlined"} component={"button"}>Перейти к тарифам</Button>
                                    </Link>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid className={'main__pic'} item lg={6} justifyContent={'flex-end'} display={'flex'}>
                            <MainPic/>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section style={{backgroundColor: "#333"}}>
                <Container>
                    <Stack pt={isMobile ? 10 : 20} pb={isMobile ? 10 : "120px"} alignItems={!isMobile && 'center'}>
                        <Stack alignItems={"center"}  width={isMobile ? '100%' : '75%'} pb={isMobile ? 10 :'160px'}>
                            <p
                                className={'span--text'}
                            >
                                <span className={'greenText'}>Быстрый интернет,</span>
                                <span className={'blueText'}> Обширное
                                    телевидение,</span>
                                <span className={'redText'}> онлайн кинотеатры</span>
                            </p>
                            <p
                                className={'span--text'}
                            >
                                <span className={'greyText'}>в одном Спектре</span>
                            </p>
                        </Stack>
                        <Stack
                            direction={isMobile ? 'column' : "row"}
                            spacing={isMobile ? 1 : 3} color={palette.white['900']}>
                            <Stack>
                                <Divider color={"#666"}/>
                                <Box p={isMobile ? 1 : 3} maxWidth={'440px'}>
                                    <Typography py={1} variant={"h3"}>Быстрый интернет и TV</Typography>
                                    <Typography variant={'primaryMedium'} color={palette.grey['400']}>Наслаждайтесь
                                        быстрым интернетом и разнообразным выбором
                                        телеканалов, обеспечивая себя качественным развлечением и связью.</Typography>
                                </Box>
                            </Stack>
                            <Stack>
                                <Divider color={"#666"}/>
                                <Box p={isMobile ? 1 : 3} maxWidth={'440px'}>
                                    <Typography py={1} variant={"h3"}>Расширенное покрытие</Typography>
                                    <Typography variant={'primaryMedium'} color={palette.grey['400']}>
                                        Мы расширяем доступ к кабельному ТВ и интернету GPON в новых районах,
                                        обеспечивая надежное подключение для жителей частного сектора.
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack>
                                <Divider color={"#666"}/>
                                <Box p={isMobile ? 1 : 3} maxWidth={'440px'}>
                                    <Typography py={1} variant={"h3"}>Удобство и гибкость</Typography>
                                    <Typography variant={'primaryMedium'} color={palette.grey['400']}>Подбирайте тарифы под ваши потребности, наслаждайтесь мультискрином, онлайн кинотеатром и полным контролем над домашней сетью.</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
                <Divider sx={{borderTop: '1px solid', borderColor: 'rgba(255, 255, 255, 0.20)'}}/>
                <BestTariff/>
            </section>
            <section>
                <Container>
                    <ChatBotTelegram/>
                </Container>
            </section>
            <section>
                <Container>
                    <Stack spacing={2} mb={6} maxWidth={650}>
                    <Typography variant={'h2'}>Новости</Typography>
                    <Typography variant={'body2'}>
                        Будьте в центре происходящего и получайте свежую информацию о наших услугах и новых
                        возможностях.
                    </Typography>
                    </Stack>
                    <PreviewNews/>
                </Container>
            </section>
            <ReadyToConnectSection/>
        </main>
    );
};

export default Home;