import {enqueueSnackbar} from "notistack";
import allEndpoints from "../../../shared/http";

const deleteIcon = async (id) => {
    try {
        const response = await allEndpoints.file.deleteIcon(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Иконка удалена', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка', error.message);
    }
};

const deleteImage = async (id) => {
    try {
        const response = await allEndpoints.file.deleteImage(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Картинка удалена', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка :', error.message);
    }
};

const deleteDocument = async (id) => {
    try {
        const response = await allEndpoints.file.deleteDocument(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Документ удален', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка', error.message);
    }
};
const deleteTariff = async (id) => {
    try {
        const response = await allEndpoints.tariff.deleteTariff(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Тариф удален', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка', error.message);
    }
};
const deletePost = async (id) => {
    console.log(allEndpoints.news);
    try {
        const response = await allEndpoints.news.deletePost(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Новость удалена', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка', error.message);
    }
};
const deleteCity = async (id) => {
    try {
        const response = await allEndpoints.city.deleteCity(id)
        // Проверка HTTP-статуса ответа
        if (response.status === 200) {
            enqueueSnackbar('Город удален', {variant: 'success'});
        } else {
            console.error('Ошибка', response.statusText);
        }
    } catch (error) {
        // Обработка ошибки сети
        console.error('Ошибка', error.message);
    }
};

export const allContent = {
    tariff: deleteTariff,
    city: deleteCity,
    post: deletePost,
    document: deleteDocument,
    icon: deleteIcon,
    image: deleteImage,
}