import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Context} from "../../index";
import {useSnackbar} from "notistack";
import allEndpoints from "../../shared/http";
import {Button, CircularProgress, Link, Stack, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import palette from "../../shared/theme/palette";
import axios from "../../shared/http/axios";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {schemaContact} from "./validation";

export const FeedbackForm = observer(({tariffName = null, cityMode = false, handleClose}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {store} = useContext(Context)
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
        setError,
        setValue,
        register
    } = useForm({
        resolver: yupResolver(schemaContact),
    });
    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            const response = await axios.post("https://www.969975-cv27771.tmweb.ru:3010/feedback",
                {
                    "subject": 'Подключение',
                    "to": 'spektr.feedback@yandex.ru',
                    "body": `Имя: ${data.name},
                                    Телефон: ${data.phone},
                                    Город: ${data.city},
                                    Тариф: ${tariffName},
                                `
                })
            if (response.status === 200) {
                enqueueSnackbar('Заявка отправлена, мы свяжемся с вами в ближайшее время', {variant: 'success'});
                if (handleClose){
                    handleClose()
                }
            } else {
                console.error('Ошибка', response.statusText);
                enqueueSnackbar('Произошла ошибка, попробуйте позже', {variant: 'error'});

            }
        } catch (error) {
            // Обработка ошибки сети
            enqueueSnackbar('Произошла ошибка, попробуйте позже', {variant: 'error'});

        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        if (store.cityOption) {
            setValue('city', store.cityOption.name || '')
        }
    }, [store.cityOption]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems={!isMobile && "flex-start"} spacing={2} width={'100%'} maxWidth={480}>
                {
                    cityMode &&
                    <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={Boolean(errors.city)}
                                className={Boolean(errors.city) ? 'animate__animated animate__headShake' : ""}
                                fullWidth
                                name="city"
                                type="text"
                                label="Ваш город"
                                variant="standard"
                                helperText={errors.city?.message}
                            />
                        )}
                    />
                }
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <TextField
                            {...field}
                            error={Boolean(errors.name)}
                            className={Boolean(errors.name) ? 'animate__animated animate__headShake' : ""}
                            fullWidth={true}
                            name='name'
                            type="text"
                            label="Ваше имя"
                            variant="standard"
                            helperText={errors.name?.message}
                        />
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <InputMask mask="8 (999) 999-99-99" {...field}>
                            {(inputProps) => (
                                <TextField
                                    {...inputProps} // Передаем props от InputMask напрямую в TextField
                                    className={Boolean(errors.phone) ? 'animate__animated animate__headShake' : ""}
                                    style={{ boxSizing: "border-box", position: "relative" }}
                                    error={Boolean(errors.phone)}
                                    fullWidth
                                    type="text"
                                    label="Телефон"
                                    variant="standard"
                                    helperText={errors.phone?.message}
                                />
                            )}
                        </InputMask>
                    )}
                />
            </Stack>
            <Stack>
                <FormControlLabel
                    sx={{py: 1}}
                    control={
                        <Controller
                            control={control}
                            name="acceptTerms"
                            defaultValue={false}
                            inputRef={register()}
                            render={({field: {onChange}}) => (
                                <Checkbox
                                    sx={{color: palette.grey['200']}}
                                    color="primary"
                                    onChange={e => onChange(e.target.checked)}
                                />
                            )}
                        />
                    }
                    label={
                        <Typography
                            variant="body2"
                            sx={{lineHeight: isMobile && '16px', fontSize: isMobile && '12px'}}
                            color={errors.acceptTerms ? 'error' : 'inherit'}>
                            Я принимаю условия обработки <Link href={'https://www.969975-cv27771.tmweb.ru:3000/assets/docs/f2f8f9ff-9b18-11ee-a300-22044dd8e4b0.pdf'}>персональных данных</Link>
                        </Typography>
                    }
                />
            </Stack>
            {
                isLoading
                    ? <CircularProgress/>
                    : <Button
                        variant={"contained"}
                        type={'submit'}
                        // onClick={sendFeedback}
                    >
                        Отправить
                    </Button>
            }
        </form>
    );
});
