import React from 'react';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const City = (props) => {
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const removeCity = () => {
        axios.post("https://www.969975-cv27771.tmweb.ru:8083/tools/remove_city", {id: props.id})
            .then(response => {
                if (response.status === 200){
                    handleCloseModal();
                }
            })
            .catch(e => {

                }
            )
            .finally(

            )
    };
    return (
        <li key={props.id} value={props.id}>
            {props.name}
            <Button onClick={handleOpenModal}>Удалить</Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Вы точно хотите удалить город: {props.name}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={removeCity}
                    >
                        удалить
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                    >
                        отмена
                    </Button>
                </Box>
            </Modal>
        </li>
    );
};

export default City;