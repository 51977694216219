import React from 'react';
import {Button, Stack, Typography} from "@mui/material";

const ErrorComponent = () => {
    return (
        <Stack width={'100%'} alignItems={'center'} spacing={2}>
            <Typography variant={'h2'}>УПС!</Typography>
            <Typography variant={'body2'}>Что-то пошло не так</Typography>
            <Button variant={'contained'} color={'error'}>Обновить страницу</Button>
        </Stack>
    );
};

export default ErrorComponent;