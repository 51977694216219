import React from 'react';

const Notification = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M19 8C19 6.4087 18.2625 4.88258 16.9497 3.75736C15.637 2.63214 13.8565 2 12 2C10.1435 2 8.36301 2.63214 7.05025 3.75736C5.7375 4.88258 5 6.4087 5 8C5 15 5 17 5 17H19C19 17 19 15 19 8Z"
                stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 21H10" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Notification;