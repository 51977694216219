import React, {useEffect, useState} from 'react';
import {useParams, Link as RouterLink} from "react-router-dom";
import allEndpoints from "../shared/http";
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    Link,
    Paper,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import TariffRowsList from "../Component/Tariff/TariffRowsList";
import TariffTypeLayout from "../entities/tariff/TariffTypeLayout";
import ReadyToConnectSection from "../widgets/feedback/ReadyToConnectSection";
import TariffImg from "../shared/svg/TariffImg";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorComponent from "../entities/ErrorComponent";
import FeedbackModal from "../entities/FeedbackModal";
import {Helmet} from "react-helmet-async";
import {Title} from "@material-ui/icons";

const SingleTariff = () => {
    const id = useParams().id;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);
    const [tariffTypes, setTariffTypes] = useState([]);

    const handleGetTariff = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.tariff.getTariff(id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                setData(response.data.res)
                setTariffTypes(response.data.res.tariff_type)
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
            setIsError(true)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleGetTariff()
    }, [])
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" to="/" component={RouterLink}>
            Главная
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            to="/tariffs"
            component={RouterLink}
        >
            Тарифы
        </Link>,
        <Link
            underline="hover"
            key="3"
            color="text.primary"
            to={`/tariff/${id}`}
            component={RouterLink}
        >
            {data?.title}
        </Link>,
    ];

    const breadcrumbsLg = [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Главная",
            "item": "https://spektr-tv.su"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Тарифы",
            "item": "https://spektr-tv.su/tariffs"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": data?.title, // Название тарифа
            "item": `https://spektr-tv.su/tariff/${id}` // Динамическая ссылка
        }
    ];
    const isMobile = useMediaQuery('(max-width:600px)');
    const title = `Тариф "${data.title}" — Интернет и ТВ за ${data.price}₽ ${data.period_per_pay} | Спектр-ТВ`;
    const description = `Откройте для себя тариф '${data.title}' от Спектр! Получите стабильный интернет и качественное телевидение всего за ${data.price}₽ ${data.period_per_pay}. Подключайтесь и наслаждайтесь высоким качеством и надежностью!`;
    const url = `https://spektr-tv.su/tariff/${id}`
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>

                {/* Open Graph meta tags */}
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={url}/>

                {/* Twitter meta tags */}
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": data.title,
                        "description": title,
                        "sku": `${data.title}-${data.price}`,
                        "brand": {
                            "@type": "Organization",
                            "name": "Спектр"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": url, // Должен быть динамическим значением
                            "priceCurrency": "RUB",
                            "price": data.price,
                            "priceValidUntil": "2025-12-31", // Текущая дата
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "https://schema.org/InStock",
                            "eligibleRegion": {
                                "@type": "Place",
                                "name": "Россия"
                            }
                        },
                        "category": "Интернет и ТВ тарифы"
                    })
                }}/>
                <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": breadcrumbsLg
                    })
                }}/>
            </Helmet>
            <Container>
                <Breadcrumbs
                    separator={<span style={{color: "#66B3EC"}}>/</span>}
                    aria-label="breadcrumb"
                    sx={{py: 4}}
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Stack spacing={10} pb={10}>
                    <Paper>
                        <Stack
                            py={isMobile ? 3 : 10} px={isMobile ? 3 : 14}
                            direction={isMobile ? 'column-reverse' : "row"}
                            justifyContent={"space-between"}
                            alignItems={'center'}
                            className={isMobile && 'main__pic'}
                            spacing={isMobile && 4}
                        >
                            <Stack spacing={6} width={isMobile ? '100%' : '40%'}>
                                <Stack>
                                    <Typography variant={"h1"}>
                                        {isLoading ? <Skeleton width={80}/> : data.title}
                                    </Typography>
                                    <Typography variant={"primaryMedium"} color={"#FF8A00"}>
                                        {isLoading ? <Skeleton width={120}/> : data.subtitle}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <TariffRowsList isLoading={isLoading} tariff_type={data.tariff_type}/>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                                    <FeedbackModal tariffName={data?.title} name={'Подключить'}/>
                                    <Stack direction={'row'} alignItems={'center'} gap={'6px'}>
                                        <Typography variant={"secondaryBold"}>за</Typography>
                                        <Typography variant={"h3"}>{data.price}</Typography>
                                        <Typography variant={"secondaryBold"}>руб</Typography>
                                        <Typography variant={"secondaryBold"} sx={{color: '#FF8A00'}} component={'p'}>
                                            {data.period_per_pay}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <TariffImg/>
                        </Stack>
                    </Paper>

                    <Stack spacing={3} maxWidth={'888px'}>
                        <Typography variant={'h2'} color={'#333'}>Что включено в тариф?</Typography>
                        <Typography variant={'primaryMedium'} color={'#333'}>{data.short_description}</Typography>
                    </Stack>

                    <Stack spacing={3}>
                        {
                            isLoading ? (
                                <CircularProgress/>
                            ) : isError ? (
                                <ErrorComponent
                                />
                            ) : (
                                tariffTypes?.map((typeRow) => (
                                    <Paper key={typeRow.ID} sx={{bgcolor: colorsByType[typeRow.type]}}>
                                        <TariffTypeLayout
                                            type_name={typeRow.type_name}
                                            description={typeRow.description}
                                            subtitle={typeRow.subtitle}
                                            icon={typeRow.icon_path}
                                        />
                                    </Paper>
                                ))
                            )
                        }
                    </Stack>


                </Stack>
            </Container>
            <ReadyToConnectSection tariffName={data.title}/>
        </>
    );
};


const colorsByType = {
    1: "#CCE6F9",
    2: "#E4F7CC",
    3: "#FFE8CC",
};

export default SingleTariff;

