import React from 'react';
import {Divider, Stack, Typography} from "@mui/material";
import TariffShortTypeLayout from "./TariffShortTypeLayout";
import useMediaQuery from "@mui/material/useMediaQuery";

const TariffTypeLayout = ({ type_name, description, subtitle, icon }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Stack p={{xs: 3, md: 5}} color={'#333'} spacing={{xs: 3, md: 4}}>
            <Typography variant={'h2'} textTransform={"uppercase"}>
                {type_name}
            </Typography>
            <Divider  color={"rgba(0, 0, 0, 0.20)"} sx={{height: 2, backgroundColor:"rgba(0, 0, 0, 0.20)"}}/>
            <Stack direction={isMobile ? 'column' : "row"} spacing={isMobile && 3} justifyContent={'space-between'}>
                <TariffShortTypeLayout
                    title={type_name}
                    subtitle={subtitle}
                    divider={false}
                    icon={icon}
                />
                <Stack spacing={3} maxWidth={'800px'} width={'100%'}>
                    <Stack spacing={'4px'}>
                        {
                            description.map((row)=>{
                                return(
                                 <>
                                     <Typography variant={"headline"}>{row.title}</Typography>
                                     <Typography variant={"primaryMedium"}>{row.body}</Typography>
                                 </>
                                )
                            })
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TariffTypeLayout;